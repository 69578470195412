import axios from "axios";
import config from "./config";
import { getDataFromLocalStorage } from "../browser-storage";

import { LocalStorageConstants } from "./localstorageconstants";

import {
  AptitudeQuestionType,
  SoftSkillQuestionType,
} from "../redux/types/model/assessment/aptitude";
import {
  AvailableAssessmentResponse,
  AssessmentType,
} from "../redux/types/model/assessment/assessment";

const getToken = () => getDataFromLocalStorage(LocalStorageConstants.TOKEN);
interface CreateRequest {
  difficulty: string;
  limits: Record<AptitudeQuestionType, number>;
  aptitudeType: string;
}
interface SoftSkillRequest {
  difficulty: string;
  limits: Record<SoftSkillQuestionType, number>;
  softSkillType: string;
}
const createAptitudeTest = async (assessmentId: string): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/assessment/create/aptitude`,
      { assessmentId },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const createModifiedAptitudeTest = async (
  assessmentId: string
): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/modified-assessment/create/aptitude`,
      { assessmentId },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const submitAptitudeTest = async (req: {
  testId: string;
  answerSheet: Record<string, number>;
}): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(`${config.PROTOCOL}${config.HOST}/assessment/submit/aptitude`, req, {
      headers,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const submitModifiedAptitudeTest = async (req: {
  testId: string;
  answerSheet: Record<string, number>;
}): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/modified-assessment/submit/aptitude`,
      req,
      {
        headers,
      }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const submitModifiedSoftSkillTest = async (req: {
  testId: string;
  answerSheet: Record<string, number>;
}): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/modified-assessment/submit/soft-skill`,
      req,
      {
        headers,
      }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const createSoftSkillTest = async (assessmentId: string): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/assessment/create/soft-skill`,
      { assessmentId },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const createModifiedSoftSkillTest = async (
  assessmentId: string
): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/modified-assessment/create/soft-skill`,
      { assessmentId },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const submitSoftSkillTest = async (req: {
  testId: string;
  answerSheet: Record<string, number>;
}): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/assessment/submit/soft-skill`,
      req,
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const createModifiedMixSkillTest = async (
  assessmentId: string
): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/modified-assessment/create/mix`,
      { assessmentId },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const submitMixSkillTest = async (req: {
  testId: string;
  answerSheet: Record<string, number>;
}): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/modified-assessment/submit/mix`,
      req,
      {
        headers,
      }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const getAvailableAssessment = async (): Promise<
  AvailableAssessmentResponse[]
> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .get(`${config.PROTOCOL}${config.HOST}/assessment/available`, { headers })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const getModifiedAvailableAssessment = async (): Promise<
  AvailableAssessmentResponse[]
> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .get(`${config.PROTOCOL}${config.HOST}/modified-assessment/available`, {
      headers,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const getAssessmentResult = async (
  assessmentType: AssessmentType,
  testId: string
): Promise<AvailableAssessmentResponse> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .get(
      `${config.PROTOCOL}${config.HOST}/assessment/result?assessmentType=${assessmentType}&testId=${testId}`,
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));

  // response

  // {
  //     "assessmentType": "APTITUDE",
  //     "testId": "620162dfe730d2bcf12e0393",
  //     "result": {
  //         "totalQues": 30,
  //         "attemptedQues": 3,
  //         "rightAns": 3,
  //         "type": {
  //             "QUANTITATIVE": {
  //                 "totalQues": 8,
  //                 "attemptedQues": 0,
  //                 "rightAns": 0
  //             },
  //             "VERBAL": {
  //                 "totalQues": 8,
  //                 "attemptedQues": 0,
  //                 "rightAns": 0
  //             },
  //             "LOGICAL": {
  //                 "totalQues": 9,
  //                 "attemptedQues": 3,
  //                 "rightAns": 3
  //             },
  //             "DATA_INTERPRETATION": {
  //                 "totalQues": 5,
  //                 "attemptedQues": 0,
  //                 "rightAns": 0
  //             }
  //         }
  //     },
  //     "difficulty": "EASY"
  // }
};

const getModifiedAssessmentResult = async (
  assessmentType: AssessmentType,
  testId: string
): Promise<AvailableAssessmentResponse> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .get(
      `${config.PROTOCOL}${config.HOST}/modified-assessment/result?assessmentType=${assessmentType}&testId=${testId}`,
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const getQuestionListUsingTestId = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/modified-assessment/get-assessment-from-test-response`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const DeleteTestResponseUsingTestId = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/modified-assessment/delete-test-response-by-id`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
export {
  createAptitudeTest,
  createModifiedAptitudeTest,
  submitModifiedSoftSkillTest,
  submitAptitudeTest,
  createSoftSkillTest,
  createModifiedSoftSkillTest,
  submitSoftSkillTest,
  getAvailableAssessment,
  getModifiedAvailableAssessment,
  getAssessmentResult,
  getModifiedAssessmentResult,
  submitModifiedAptitudeTest,
  createModifiedMixSkillTest,
  submitMixSkillTest,
  getQuestionListUsingTestId,
  DeleteTestResponseUsingTestId,
};
