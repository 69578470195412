import React, { useEffect, useState } from "react";

import { getSchedulerlisting } from "../../services/scheduler-service";

import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Wrapper from "../../layout/wrapper";
const ref = React.createRef();
const AdminSchedulerListings = () => {
  const [editMode, setEditMode] = useState(false);
  const [SchedulerList, setSchedulerList] = useState([]);

  useEffect(() => {
    const resp = getSchedulerlisting()
      .then((res) => {
        setSchedulerList(res);
        console.log(SchedulerList);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(SchedulerList);
  }, []);

  return (
    <>
      <Wrapper>
        <Table style={{ margin: 10 }} striped bordered hover>
          <thead>
            <tr>
              <th>Source</th>
              <th>username</th>
              <th>fullname</th>
              <th>mobile</th>
              {/* <th>college</th>
              <th>degree</th> */}
              <th>servicebooked</th>
              <th>bookingDate</th>
              <th>amountPaid</th>
              {/* <th>bonusEarned</th>
              <th>totalBalance</th>
              <th>razorpayPaymentId</th>
              <th>razorpayOrderId</th>
              <th>razorpaySignature</th> */}
            </tr>
          </thead>
          <tbody>
            {SchedulerList.map((item) => {
              return (
                <tr>
                  <td>{item.source}</td>
                  <td>{item.username}</td>
                  <td>{item.fullname}</td>
                  <td>{item.mobile}</td>
                  {/* <td>{item.college}</td>
                  <td>{item.degree}</td> */}
                  <td>{item.servicebooked}</td>
                  <td>{item.bookingDate}</td>
                  <td>{item.amountPaid}</td>
                  {/* <td>{item.bonusEarned}</td>
                  <td>{item.totalBalance}</td>
                  <td>{item.razorpayPaymentId}</td>
                  <td>{item.razorpayOrderId}</td>
                  <td>{item.razorpaySignature}</td> */}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Wrapper>
    </>
  );
};
export default AdminSchedulerListings;
