import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import ProgressBar from "./common/progressBar";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import {
  AssessmentType,
  ScoreCardConstants,
} from "../../../redux/types/generic-constant";
import {
  DeleteTestResponseUsingTestId,
  getAssessmentResult,
  getModifiedAssessmentResult,
  getQuestionListUsingTestId,
} from "../../../services/assessment";
import {
  AptitudeResponse,
  QuestionType,
  SoftSkillResponse,
} from "../../../redux/types/model/assessment/aptitude";
import { useReactToPrint } from "react-to-print";
import { getQueryParams } from "../../../helpers";
import { AppConfigEvent } from "../../../redux/types/redux-event";
import { toast } from "react-toastify";
import Wrapper from "../../../layout/wrapper";
import "./score-card.css";
import { LOGO_URL } from "../../../layout/headers/header";
import { Card } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
//Complete later
//import Header from "components/dashboard-header";

// interface StyledPros {
//   scorePer: number
//   paddingTop?: string
//   fontWeight?: number
//   fontSize?: number
//   paddLeft?: number
//   mrgBtm?: number
//   color?: string
// }

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: 70vh;
  padding-left: 15px;
  padding-right: 40px;
  background: #ffffff;
  justify-content: center;
  //align-items: center;
`;
const HeadText = styled.div`
  display: flex;
  width: 100%;
  //max-width: 435px;
  padding-left: 25px;
  box-shadow: 0 3px 3px 3px #c4c4c4;
  color: #3e3d43;
  font-size: 28px;
  font-weight: 500;
  padding-bottom: 30px;
  padding-top: 30px;
`;
const UserPerformance = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  border-left: 6px solid #e0e0e0;
`;

const InformationBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 42px;
  justify-content: space-between;
`;
const UserInfoColumn = styled.div`
  display: flex;
  width: 100%;
  max-width: 300px;
`;
const RemarksColumn = styled.div`
  display: flex;
  width: 100%;
  max-width: 1100px;
  //flex-direction: column;
`;
const PercentageColumn = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  box-shadow: 1px 3px 3px 3px #c4c4c4;
  // max-height: 130px;
  padding: 20px;
`;
const UserDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  align-items: center;
  padding-left: 20px;
  max-height: 150px;
  border-left: 6px solid #e0e0e0;
`;
const Fields = styled.span`
  display: flex;
  width: 100%;
  max-width: 350px;
  color: rgba(62, 61, 67, 0.74);
  font-size: 13px;
  align-items: center;
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "0")};
`;

const FieldValue = styled.span`
  display: flex;
  font-size: 13px;
  align-items: center;
  padding-left: 5px;
  color: black;
`;
const ProgressBarContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-weight: 600;
  max-width: 950px;
  min-width: 550px;
  border-radius: 2px;
  background-color: #f5f5f5;
`;
const ProgressBarBlock = styled.div`
  display: flex;
  width: ${(props) => (props.scorePer ? `${props.scorePer}%` : "0%")};
  height: 30px;
  border-radius: 2px;
  background-color: ${(props) =>
    props.scorePer <= 30
      ? "#EA5252"
      : props.scorePer > 31 && props.scorePer <= 50
      ? "#90EE90"
      : props.scorePer > 51 && props.scorePer <= 70
      ? "#90EE90"
      : "#1C8D39"};
  flex-direction: column;
  align-items: center;
`;

const SpanText = styled.div`
  display: flex;
  width:200px
  flex-direction: row;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "23px")};
  font-weight: ${(props) => (props.fontWeight ? `${props.fontWeight}` : "500")};
  margin-bottom: ${(props) => (props.mrgBtm ? `${props.mrgBtm}` : "unset")};
  padding-left: ${(props) =>
    props.paddLeft ? `${props.paddLeft}px` : "unset"};
  color: ${(props) => (props.paddLeft ? `${props.color}` : "#3E3D43")};
  align-items: center;
`;

const OverallProgress = styled.div`
  display: flex;
  width: 100%;
  background: ${(props) =>
    props.scorePer <= 30
      ? "#EA5252"
      : props.scorePer > 31 && props.scorePer <= 50
      ? "#F9A825"
      : props.scorePer > 51 && props.scorePer <= 70
      ? "#0DADAD"
      : "#1C8D39"};
  height: 34px;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 25px;
`;

const DetailsBlock = styled.div`
  width: 100%;
  //padding-left: 20px;
  display: flex;
  flex-direction: column;
`;
const OverallBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`;
const PerDiv = styled.div`
  width: 100%;
  background: #7e7e7e;
  color: #ffffff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 10px 1px 10px;
  max-height: 60px;
  margin-left: 15px;
`;
const AllProgressbar = styled.div`
  width: 100%;
  display: flex;
  padding: 20px;
  border: 1px solid #c4c4c4;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.11);
  margin-top: 22px;
`;
const IndividualProgressBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

const ScoreCardPage = () => {
  const componentRef = useRef();
  const navigate = useNavigate();
  const _user = localStorage.getItem("user");
  let handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // const { userProfile } = useSelector(({ userProfile }: RootState) => ({
  //   userProfile,
  // }))
  //check Once yash
  const userProfile = useSelector((state) => state.profile);
  console.log(userProfile);
  const dispatch = useDispatch();
  const [testData, setData] = useState({});
  //const [current_type, setCurrentType] = useState("");
  const assessmentType = getQueryParams("type");
  //setCurrentType(assessmentType);
  console.log("assessmentType", assessmentType);

  const [currentTestId, setCurrentTestId] = useState(null);

  useEffect(() => {
    const testId = getQueryParams("id");
    setCurrentTestId(testId);
    getTestData(assessmentType, testId);
    getQuestionsusingtestIdFunc(testId);
  }, []);

  const [checkattempted, setattempted] = useState(null);

  const getTestData = async (assessmentType, testId) => {
    dispatch({ type: AppConfigEvent.START_LOADING });
    try {
      //replace normal one with modified
      const res = await getModifiedAssessmentResult(assessmentType, testId);
      console.log("res", res);
      setData(res);
    } catch (err) {
      toast.error(`Unable to get your scorecard `, {
        position: "top-left",
      });
    } finally {
      dispatch({ type: AppConfigEvent.STOP_LOADING });
    }
  };
  const overallMarksPer =
    assessmentType === AssessmentType.APTITUDE
      ? (testData?.result?.rightAns / testData?.result?.totalQues) * 100
      : (testData?.result?.score / (testData?.result?.totalQues * 5)) * 100;
  const type =
    assessmentType === AssessmentType.APTITUDE
      ? testData?.result?.type
      : testData?.result?.type;
  const performanceOnPercentage = (percentage) => {
    if (percentage <= 30) return "Very Low";
    else if (percentage >= 31 && percentage <= 50) return "Low";
    else if (percentage >= 51 && percentage <= 70) return "Moderately High";
    return "High";
  };

  const [QuestionAnswer, setQuestionAnswer] = useState([]);

  const getQuestionsusingtestIdFunc = async (testId) => {
    const res = await getQuestionListUsingTestId(testId)
      .then((res) => {
        console.log("res", res);
        const questionData = res.question;
        console.log("questionData", questionData);
        setQuestionAnswer(questionData);
      })
      .catch((err) => {
        console.log("err", err);
      });
    console.log("res", res);
  };
  const getCorrectAnswer = (question) => {
    const correctOptionIndex = question.answer;
    return correctOptionIndex >= 0 &&
      correctOptionIndex < question.options.length
      ? question.options[correctOptionIndex]
      : null;
  };
  function getPerformanceColor(percentage) {
    if (percentage <= 30) return "red"; // Very Low
    else if (percentage >= 31 && percentage <= 50) return "#F9A825"; // Low
    else if (percentage >= 51 && percentage <= 70) return "green"; // Moderately High
    return "green"; // High
  }
  function getOverallScoreColor() {
    const overallScore = isFinite(overallMarksPer)
      ? performanceOnPercentage(overallMarksPer)
      : 0;
    console.log("overallScore", overallScore);
    if (overallScore == "Low") return "#F9A825"; // Low
    else if (overallScore == "Very Low") return "#F9A825";
    return "green"; // High
  }

  const retryAssessment = async () => {
    console.log("button clicked");
    console.log("currentTestId", currentTestId);
    const res = await DeleteTestResponseUsingTestId(currentTestId)
      .then((res) => {
        console.log("res", res);

        navigate("/behavioral-available-test", { replace: true });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      {/* 
      check later
      <Header
        headerConfig={{
          ghLogo: true,
          searchBar: false,
          home: true,
          msg: true,
          notification: true,
          avatarControl: true,
          timeTicker: false,
        }}
      /> */}
      <Wrapper>
        <div className="container">
          <div className="row mt-20">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <div style={{ float: "right" }}>
                <button onClick={handlePrint} className="e-btn">
                  Download PDF
                </button>
              </div>
            </div>
          </div>
        </div>
        <div ref={componentRef}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              // height: '70vh',
              paddingLeft: "15px",
              paddingRight: "40px",
              background: "#ffffff",
              justifyContent: "center",

              // alignItems: 'center',
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                boxShadow: "0 3px 3px 3px #c4c4c4 ",
                paddingLeft: 15,
                paddingRight: 40,
                padding: 20,
                marginTop: 20,
              }}
            >
              <div style={{ flexGrow: 1 }}>
                <h3>
                  {ScoreCardConstants[
                    assessmentType ? assessmentType : AssessmentType.APTITUDE
                  ] +
                    " " +
                    "Self Assessment Report"}
                </h3>
              </div>
              <div>
                <img
                  style={{ maxWidth: "100%", height: "auto" }}
                  width={100}
                  src={LOGO_URL}
                />
              </div>
            </div>

            {/* {testData &&
              testData.result &&
              testData.result.type &&
              testData.result.type.SELF_AWARENESS.totalQues}

            {console.log(
              "SELF_AWARENESS",
              testData.result.type.SELF_AWARENESS.totalQues
            )} */}

            {testData &&
            testData.result &&
            testData.result.attemptedQues == "0" ? (
              //   ||
              // (testData.result.type?.["EXTRAVERSION"] &&
              //   testData.result.type.EXTRAVERSION.totalQues > 0)
              // &&
              // testData.result.type?.SELF_AWARENESS &&
              // testData.result.type.SELF_AWARENESS.totalQues > 0
              <Card className="mt-20">
                <p style={{ padding: 20 }}>
                  You didn't submit your test properly, we are unable to
                  generate your report
                </p>
                <div className="row text-center">
                  <div className="">
                    {assessmentType == "SOFT_SKILL" ? (
                      <button
                        className="e-btn"
                        onClick={() => retryAssessment()}
                      >
                        Try Again
                      </button>
                    ) : null}
                  </div>
                </div>
              </Card>
            ) : (
              <>
                <div
                  className=""
                  style={{
                    boxShadow: "0 3px 3px 3px #c4c4c4",
                    paddingLeft: 15,
                    paddingRight: 40,
                    padding: 20,
                    marginTop: 20,
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div
                        style={{
                          flex: "1 1 100%",
                          maxWidth: "100%",
                          marginBottom: "10px",
                        }}
                      >
                        <p style={{ fontSize: 18 }}>
                          Name : {userProfile.firstName} {userProfile.lastName}
                        </p>
                      </div>
                      <div
                        style={{
                          flex: "1 1 100%",
                          maxWidth: "100%",
                          marginBottom: "10px",
                        }}
                      >
                        <p style={{ fontSize: 18 }}>
                          Date : {moment(Date.now()).format("DD/MM/yy")}
                        </p>
                      </div>

                      <div
                        style={{
                          flex: "1 1 100%",
                          maxWidth: "100%",
                          marginBottom: "10px",
                        }}
                      >
                        <p style={{ fontSize: 18 }}>
                          Overall Score :{" "}
                          <span style={{ color: getOverallScoreColor() }}>
                            {isFinite(overallMarksPer)
                              ? performanceOnPercentage(overallMarksPer)
                              : 0}
                          </span>
                        </p>
                      </div>
                      {type &&
                        Object.keys(type).length > 0 &&
                        Object.keys(type).map((key, index) => {
                          const score =
                            assessmentType === AssessmentType.APTITUDE
                              ? (type[key].rightAns / type[key].totalQues) * 100
                              : (type[key].score / type[key].totalQues) * 5 * 4;
                          const performance = performanceOnPercentage(score);
                          const performanceColor = getPerformanceColor(score);
                          return (
                            <div
                              key={index}
                              style={{
                                flex: "1 1 100%",
                                maxWidth: "100%",
                                marginBottom: "10px",
                              }}
                            >
                              <p style={{ fontSize: 18 }}>
                                {ScoreCardConstants[key]} :{" "}
                                <span style={{ color: performanceColor }}>
                                  {performance}
                                </span>
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    padding: "20px",
                    border: "1px solid #c4c4c4",
                    flexDirection: "column",
                    boxSizing: "border-box",
                    boxShadow: "0 3px 3px rgba(0, 0, 0, 0.11)",
                    marginTop: "22px",
                  }}
                >
                  {type &&
                    Object.keys(type).length > 0 &&
                    Object.keys(type).map((key, index) => {
                      const score =
                        assessmentType === AssessmentType.APTITUDE
                          ? (type[key].rightAns / type[key].totalQues) * 100
                          : (type[key].score / type[key].totalQues) * 5 * 4;
                      return (
                        <>
                          <div className="mobile">
                            <div
                              key={index}
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <SpanText
                                  fontWeight={600}
                                  paddLeft={22}
                                  fontSize={19}
                                  color={
                                    index === 0
                                      ? "#5A974F"
                                      : index === 1
                                      ? "#538CD5"
                                      : index === 2
                                      ? "#953734"
                                      : "#595959"
                                  }
                                >
                                  {ScoreCardConstants[key]}:
                                </SpanText>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <ProgressBar
                                  isPrcntShow={true}
                                  scorePer={score}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="desktop">
                            <IndividualProgressBlock>
                              <SpanText
                                fontWeight={600}
                                paddLeft={22}
                                fontSize={19}
                                color={
                                  index === 0
                                    ? "#5A974F"
                                    : index === 1
                                    ? "#538CD5"
                                    : index === 2
                                    ? "#953734"
                                    : "#595959"
                                }
                              >
                                {ScoreCardConstants[key]}:
                              </SpanText>
                              <ProgressBar
                                isPrcntShow={true}
                                scorePer={score}
                              />
                            </IndividualProgressBlock>
                          </div>
                        </>
                      );
                    })}
                </div>
                <div className="SoftSkillsContainer">
                  <div
                    className=""
                    style={{
                      paddingLeft: 40,
                      paddingRight: 40,
                      paddingBottom: 40,
                    }}
                  >
                    {type &&
                      Object.keys(type).length > 0 &&
                      Object.keys(type).map((key, index) => {
                        const score =
                          assessmentType === AssessmentType.APTITUDE
                            ? (type[key].rightAns / type[key].totalQues) * 100
                            : (type[key].score / type[key].totalQues) * 5 * 4;

                        return (
                          <>
                            <div className="row">
                              {ScoreCardConstants[key] === "Self Awareness" ? (
                                <>
                                  <div
                                    className="row"
                                    paddingTop={"15px"}
                                    style={{
                                      border: "1px solid #c4c4c4",
                                      padding: 20,
                                      marginTop: 10,
                                    }}
                                  >
                                    <p>
                                      <span
                                        style={{
                                          color: "#5A974F",
                                          fontWeight: 600,
                                          fontSize: 19,
                                        }}
                                      >
                                        Self Awareness:
                                      </span>{" "}
                                      <span
                                        style={{
                                          color: "#5A974F",
                                          fontWeight: 400,
                                        }}
                                      >
                                        Are you aware of your strengths and
                                        weaknesses? Do you know what motivates
                                        you or distracts you? Knowing yourself
                                        helps you strategize your career
                                        efficiently by aligning skills properly
                                      </span>
                                    </p>
                                    <p>
                                      {" "}
                                      <span
                                        style={{
                                          color: "#5A974F",
                                          fontWeight: 600,
                                          fontSize: 19,
                                        }}
                                      >
                                        Score :{" "}
                                      </span>
                                      <span
                                        style={{
                                          color: "#5A974F",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {score}
                                      </span>
                                    </p>
                                    {score <= 30 ? (
                                      <p>
                                        Your score suggests that you have very
                                        limited awareness of your abilities and
                                        interests. You should not doubt about
                                        your abilities or avoid trying new
                                        things thinking that you may fail.
                                        Instead set small goals for yourself and
                                        try achieving them. Make a list of your
                                        strengths and weaknesses and create an
                                        action plan to work on them individually
                                      </p>
                                    ) : null}
                                    {score >= 31 && score <= 50 ? (
                                      <p>
                                        Your score suggests that you have little
                                        awareness of your abilities and
                                        interests. Please spend substantial time
                                        reflecting on your strengths and
                                        weaknesses. Think about past incidents
                                        which reflect either a strength or a
                                        weakness. Once you have identified your
                                        major strengths and weaknesses, start
                                        working on them. Also do not be afraid
                                        to try out new things thinking that you
                                        may fail
                                      </p>
                                    ) : null}
                                    {score >= 51 && score <= 70 ? (
                                      <p>
                                        Your score suggests that you are
                                        somewhat aware of your abilities and
                                        interests. Please spend time noting down
                                        your strengths and weaknesses before you
                                        start working on them. Stay focused on
                                        your goals and do not let the fear of
                                        failure affect you. Remember, only if
                                        you know what you need to fix, can you
                                        actually fix them
                                      </p>
                                    ) : null}
                                    {score >= 71 ? (
                                      <p>
                                        Your score suggests that you are quite
                                        aware of your abilities and interests
                                        and you are comfortable in trying out
                                        new things. Please continue to focus on
                                        developing your strengths further and
                                        addressing your weaknesses. Plan on
                                        setting at least 3 goals for yourself
                                        that you can achieve in 6 months
                                      </p>
                                    ) : null}
                                  </div>
                                </>
                              ) : null}

                              {ScoreCardConstants[key] === "Communication" ? (
                                <>
                                  <div
                                    className="row"
                                    paddingTop={"15px"}
                                    style={{
                                      border: "1px solid #c4c4c4",
                                      padding: 20,
                                      marginTop: 10,
                                    }}
                                  >
                                    <p>
                                      <span
                                        style={{
                                          color: "#538CD5",
                                          fontWeight: 600,
                                          fontSize: 19,
                                        }}
                                      >
                                        Communication:
                                      </span>{" "}
                                      <span
                                        style={{
                                          color: "#538CD5",
                                          fontWeight: 400,
                                        }}
                                      >
                                        Are you a confident speaker who is not
                                        afraid to communicate in front of people
                                        and one who knows how to maintain
                                        composure and body language? Are you
                                        someone who can think logically and can
                                        convey your thoughts clearly?
                                      </span>
                                    </p>
                                    <p>
                                      {" "}
                                      <span
                                        style={{
                                          color: "#538CD5",
                                          fontWeight: 600,
                                          fontSize: 19,
                                        }}
                                      >
                                        Score :{" "}
                                      </span>
                                      <span
                                        style={{
                                          color: "#538CD5",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {score}
                                      </span>
                                    </p>
                                    {score <= 30 ? (
                                      <p>
                                        Your score suggests that you face
                                        significant challenges while
                                        communicating in a professional set up.
                                        You have multiple factors to address
                                        across both verbal and non-verbal
                                        communication. You would need to focus
                                        on each aspect individually and work on
                                        them for several weeks. Create your list
                                        of challenges and work with your mentor
                                        to develop an action plan.
                                      </p>
                                    ) : null}
                                    {score >= 31 && score <= 50 ? (
                                      <p>
                                        Your score suggests that you struggle
                                        with communication. Some of the
                                        challenges could be unfamiliarity with
                                        English as a language, low confidence
                                        level, negative body language, or
                                        difficulty in structuring your thoughts.
                                        Start using simple techniques mentioned
                                        in Confidence Builder and gradually
                                        progress to an intermediate level
                                        speaker.
                                      </p>
                                    ) : null}
                                    {score >= 51 && score <= 70 ? (
                                      <p>
                                        Your score suggests that you are able to
                                        structure your thoughts and communicate
                                        with people without too much hesitation.
                                        There is certainly a lot of room for
                                        improvement. Focus more on what you want
                                        to say rather than thinking too much
                                        about pronunciation, grammar or
                                        vocabulary. Use some of the techniques
                                        used in Confidence Builder to improve
                                        yourself
                                      </p>
                                    ) : null}
                                    {score >= 71 ? (
                                      <p>
                                        Your score suggests that you are quite
                                        comfortable in your own skin. You do not
                                        feel scared to articulate your ideas
                                        while speaking to different audiences.
                                        You should take your communication to
                                        the next level by practicing the
                                        advanced level techniques mentioned in
                                        Confidence Builder such as “Just A
                                        Minute”
                                      </p>
                                    ) : null}
                                  </div>
                                </>
                              ) : null}
                              {ScoreCardConstants[key] === "Collaboration" ? (
                                <>
                                  <div
                                    className="row"
                                    paddingTop={"15px"}
                                    style={{
                                      border: "1px solid #c4c4c4",
                                      padding: 20,
                                      marginTop: 10,
                                    }}
                                  >
                                    <p>
                                      <span
                                        style={{
                                          color: "#953734",
                                          fontWeight: 600,
                                          fontSize: 19,
                                        }}
                                      >
                                        Collaboration:
                                      </span>{" "}
                                      <span
                                        style={{
                                          color: "#953734",
                                          fontWeight: 400,
                                        }}
                                      >
                                        Are you someone who can work effectively
                                        with people and drive results
                                        successfully? Are you someone who can
                                        lead discussions and help your team come
                                        up with winning ideas?
                                      </span>
                                    </p>
                                    <p>
                                      {" "}
                                      <span
                                        style={{
                                          color: "#953734",
                                          fontWeight: 600,
                                          fontSize: 19,
                                        }}
                                      >
                                        Score :{" "}
                                      </span>
                                      <span
                                        style={{
                                          color: "#953734",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {score}
                                      </span>
                                    </p>
                                    {score <= 30 ? (
                                      <p>
                                        Your score suggests that you face
                                        significant challenges while working in
                                        a group setting. You seem to be lacking
                                        most of the competencies required to
                                        manage team dynamics. You would need to
                                        focus on each aspect individually and
                                        work on them for several weeks. The
                                        “Group Discussion” section under
                                        Confidence Builder will provide you an
                                        outline on what you need to do to drive
                                        successful conversations in a group.
                                      </p>
                                    ) : null}
                                    {score >= 31 && score <= 50 ? (
                                      <p>
                                        Your score suggests that you are not
                                        comfortable working in a team set up.
                                        You often struggle with basics such as
                                        structuring ideas, maintaining positive
                                        body language and eye contact, and
                                        speaking up when needed. You need to
                                        develop these areas considerably by
                                        practicing techniques mentioned in the
                                        “Group Discussion” section of
                                        “Confidence Builder”
                                      </p>
                                    ) : null}
                                    {score >= 51 && score <= 70 ? (
                                      <p>
                                        Your score suggests that you are able to
                                        work in a group setting without too many
                                        challenges. There are certainly a number
                                        of things which you can improve
                                        including openness to diverse ideas,
                                        being more structured in your
                                        conversations, being assertive when
                                        required, and also displaying team
                                        leadership traits
                                      </p>
                                    ) : null}
                                    {score >= 71 ? (
                                      <p>
                                        Your score suggests that you are adept
                                        in working in a group setting and ideate
                                        with your peers. You also exhibit strong
                                        qualities to lead your team as needed.
                                        Continue to develop your skills as a
                                        team player by exhibiting positive body
                                        language, by staying open to ideas, and
                                        by leading your team in a structured way
                                      </p>
                                    ) : null}
                                  </div>
                                </>
                              ) : null}
                              {ScoreCardConstants[key] === "Stress Handling" ? (
                                <>
                                  <div
                                    className="row"
                                    paddingTop={"15px"}
                                    style={{
                                      border: "1px solid #c4c4c4",
                                      padding: 20,
                                      marginTop: 10,
                                    }}
                                  >
                                    <p>
                                      <span
                                        style={{
                                          color: "#595959",
                                          fontWeight: 600,
                                          fontSize: 19,
                                        }}
                                      >
                                        Stress Handling:
                                      </span>{" "}
                                      <span
                                        style={{
                                          color: "#595959",
                                          fontWeight: 400,
                                        }}
                                      >
                                        Are you someone who can stay calm and
                                        composed while handling pressure? Are
                                        you someone who can think on your feet
                                        even in unfamiliar situations?
                                      </span>
                                    </p>
                                    <p>
                                      {" "}
                                      <span
                                        style={{
                                          color: "#595959",
                                          fontWeight: 600,
                                          fontSize: 19,
                                        }}
                                      >
                                        Score :{" "}
                                      </span>
                                      <span
                                        style={{
                                          color: "#595959",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {score}
                                      </span>
                                    </p>
                                    {score <= 30 ? (
                                      <p>
                                        Your score suggests that you often
                                        perform poorly under pressure. There is
                                        no single reason why it happens to you.
                                        Rather it is a combination of various
                                        factors including fear of failure, lack
                                        of confidence towards accomplishing new
                                        things, not knowing when to ask for help
                                        or say “no”. You need to list down all
                                        the reasons that make you vulnerable and
                                        then discuss an action plan with your
                                        mentor.
                                      </p>
                                    ) : null}
                                    {score >= 31 && score <= 50 ? (
                                      <p>
                                        Your score suggests that you are
                                        vulnerable under pressure. You tend to
                                        underperform when put under stress. You
                                        need a step by step approach to identify
                                        what causes most stress in your life and
                                        how you can try managing those scenarios
                                        proactively. You will learn a variety of
                                        techniques in “Confidence Builder” to
                                        overcome stress.
                                      </p>
                                    ) : null}
                                    {score >= 51 && score <= 70 ? (
                                      <p>
                                        Your score suggests that you can
                                        generally handle pressure, although
                                        there are occasional instances when you
                                        feel overwhelmed. There are a number of
                                        techniques that you can practice to
                                        overcome stress. Most important of these
                                        is to give it your best shot and accept
                                        failure as a part of life
                                      </p>
                                    ) : null}
                                    {score >= 71 ? (
                                      <p>
                                        Your score suggests that you are very
                                        comfortable in dealing with unfamiliar
                                        situations. You do not go blank in
                                        stressful conditions and can think on
                                        your feet. Continue to develop your core
                                        skills to fight with stress by adopting
                                        techniques discussed under “Confidence
                                        Builder”
                                      </p>
                                    ) : null}
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </>
                        );
                      })}
                  </div>

                  {assessmentType !== "SOFT_SKILL" ? (
                    <>
                      {testData &&
                      testData.result &&
                      testData.result.attemptedQues == "0" ? (
                        <p></p>
                      ) : (
                        <>
                          {" "}
                          <div
                            className=""
                            style={{
                              boxShadow: "0 3px 3px 3px #c4c4c4",
                              paddingLeft: 15,
                              paddingRight: 40,
                              padding: 20,
                              marginTop: 20,
                              width: "100%",
                              boxSizing: "border-box",
                            }}
                          >
                            <h2>Test Question & Answers</h2>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {QuestionAnswer.map((question, index) => (
                                <div
                                  style={{ marginTop: 10 }}
                                  key={question.questionId}
                                >
                                  <p style={{ fontWeight: 600 }}>
                                    {index + 1} Q : {question.questionText}
                                  </p>

                                  <p
                                    style={{
                                      fontWeight: 600,
                                      color: "#016738",
                                    }}
                                  >
                                    A :{" "}
                                    {getCorrectAnswer(question) ||
                                      "Not provided"}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  );
};
export default ScoreCardPage;
