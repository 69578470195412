import { useCalendlyEventListener, InlineWidget } from "react-calendly";

import React, { forwardRef, useState, useEffect } from "react";
import JobsPopupLoginForm from "../../pages/jobs/jobs-popup-login-form";
import { getDataFromLocalStorage } from "../../browser-storage";
import Modal from "react-modal";
import Wrapper from "../../layout/wrapper";
import { getWhiteLabelConfig } from "../../services/whitelabel-config";
import {
  AddStudentCouponRecordService,
  checkIsCouponValid,
} from "../../services/coupon-service";
import { razorpayCreateOrderWithSplitPayment } from "../../services/payment-service";
import { addserviceScheduler } from "../../services/scheduler-service";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
let fullHostname = window.location.hostname;
const WhitePaidOneOnOneBooking = () => {
  const navigate = useNavigate();
  const [isDoneScheduling, setDoneScheduling] = useState(false);
  const [isscheduledone, setscheduledone] = useState(false);
  const [isTimeSelected, setTimeSelected] = useState(false);
  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => {
      if (e) {
        setscheduledone(true);
        setscheduledone(true);
      }
    },
  });

  const pageSettings = {
    primaryColor: "417658",
    textColor: "251e35",
    backgroundColor: "f7f5f4",
    hideLandingPageDetails: true,
    hideEventTypeDetails: true,
  };

  function getCalendlyHeightToEnsureNoCrop() {
    if (!isTimeSelected) return { height: 600 };
    if (isDoneScheduling) return { height: 450 };
    return { height: 660 };
  }

  const [isLoggedInStatus, setisLoggedInStatus] = useState(false);

  let checkLoginStatus = async () => {
    const userDetials = getDataFromLocalStorage("user-details");
    console.log(userDetials);
    let token = userDetials.token;
    console.log("token", token);
    if (token) {
      setisLoggedInStatus(true);
    }
    console.log("isLoggedInStatus", isLoggedInStatus);
  };

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "600px",
    },
  };
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  let applyJobHandler = () => {
    openModal();
  };

  const [Hostname, setHostname] = useState();
  const [isRazorpayPaymentDone, setisRazorpayPaymentDone] = useState("false");
  const [payments, setpayments] = useState({
    IsBookingPaid: "",
    SessionBookingPrice: "",
    MentorCouponKey: "",
    MentorCalendlyId: "",
  });
  const _user = localStorage.getItem("user");

  useEffect(() => {
    const Hostname = window.location.hostname;
    setHostname(Hostname);
    const res = getWhiteLabelConfig(Hostname)
      .then((data) => {
        //console.log(data);
        //dispatch(GET_WHITELABEL_CONFIG(data));
        console.log("Header data", data);

        const IsBookingPaid = data[0].IsBookingPaid;
        if (IsBookingPaid == "false") {
          setisRazorpayPaymentDone("true");
        }
        const SessionBookingPrice = data[0].SessionBookingPrice;
        const MentorCouponKey = data[0].MentorCouponKey;
        const MentorCalendlyId = data[0].MentorCalendlyId;
        //console.log("GuestMenuData", GuestMenuData);
        //console.log("StudentMenuData", StudentMenuData);

        setpayments({
          IsBookingPaid: IsBookingPaid,
          SessionBookingPrice: SessionBookingPrice,
          MentorCouponKey: MentorCouponKey,
          MentorCalendlyId: MentorCalendlyId,
        });
      })
      .catch((err) =>
        // toast.error(`Unable to get White label Data. Contact US `, {
        //   position: "top-left",
        // })
        console.log("White Error", err)
      );
    setHostname(fullHostname);

    console.log();
  }, [fullHostname]);

  return (
    <>
      <Wrapper>
        <div className="container">
          <div className="row mt-10">
            <div className="col-md-9"></div>
            <div className="col-md-3">
              <Link className="e-btn" to={"/"}>
                Back to Home
              </Link>
            </div>
          </div>
        </div>
        {payments.MentorCalendlyId ? (
          <>
            <InlineWidget
              url={payments.MentorCalendlyId}
              pageSettings={pageSettings}
              styles={getCalendlyHeightToEnsureNoCrop()}
            />
          </>
        ) : null}
      </Wrapper>
    </>
  );
};

export default WhitePaidOneOnOneBooking;
