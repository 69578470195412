import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";

import { useState } from "react";
import { toast } from "react-toastify";
import { AddStudentEnquiryForm } from "../../services/user-service";

import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";
import YouTube from "react-youtube";
const Video = styled.iframe`
  display: flex;
  align-items: center;
  border: none;
  max-width: 490px;
  width: 100%;
  height: 100%;
  max-height: 276px;
  min-height: 276px;
  box-shadow: 9px 14px 15px rgba(0, 0, 0, 0.25);
`;
const WhiteEnquiryForm = () => {
  const [error, setError] = useState("");
  const CurrentDate = new Date()
    .toJSON()
    .slice(0, 10)
    .split("-")
    .reverse()
    .join("/");
  const [details, setDetails] = useState({
    fullname: "",
    mobile: "",
    preferreddomain: "",
    submitteddate: CurrentDate,
  });

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!details.fullname || !details.mobile) {
      return;
    }

    const SubmitResponse = await AddStudentEnquiryForm(details)
      .then((resp) => {
        setDetails({ fullname: "", mobile: "", preferreddomain: "" });
        toast.success(`Submitted Successfully`, {
          position: "top-left",
        });
      })
      .catch((err) =>
        toast.error(`Error ${err}`, {
          position: "top-left",
        })
      );
  };

  const opts = {
    width: "100%",
    height: "265",

    playerVars: {
      autoplay: 0,
    },
  };

  let _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  const [currentvidetoplay, setCurrentvideotoplay] = useState({
    videoCode: "FLrSxW9v9OA",
  });
  const setSeletedvideotoplay = (item) => {
    console.log("item", item);
    if (item == "Student1") {
      setCurrentvideotoplay({
        videoCode: "FLrSxW9v9OA",
      });
    }
    if (item == "Student2") {
      setCurrentvideotoplay({
        videoCode: "QqDya24v0R4",
      });
    }
    if (item == "GHIntro") {
      setCurrentvideotoplay({
        videoCode: "pwnnUnwDIko",
      });
    }
  };
  return (
    <>
      <div className="container pt-20">
        <div className="row">
          <div className="">
            <div className="padding20">
              <form onSubmit={submitHandler}>
                <div className="form-inner">
                  <div className="form-group p-b-15">
                    <input
                      type="text"
                      name="fullname"
                      id="fullname"
                      placeholder="Full Name"
                      onChange={(e) =>
                        setDetails({ ...details, fullname: e.target.value })
                      }
                      className="form-control "
                      value={details.fullname}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group p-b-15">
                    <input
                      type="number"
                      name="mobile"
                      placeholder="Mobile No."
                      autoComplete="off"
                      className="form-control "
                      onChange={(e) =>
                        setDetails({ ...details, mobile: e.target.value })
                      }
                      value={details.mobile}
                    />
                  </div>
                </div>
                {error != "" ? <div className="error">{error}</div> : ""}
                <input
                  type="submit"
                  value="Submit"
                  style={{ marginTop: "20px" }}
                  className="e-btn"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <></>
    </>
  );
};

export default WhiteEnquiryForm;
