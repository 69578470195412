import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../components/common/sidebar";
import useSticky from "../../hooks/use-sticky";
import { menu_bar } from "../../redux/features/header-slice";
import NavMenu from "./nav-menu";
import { useSelector } from "react-redux";
import { getWhiteLabelConfig } from "../../services/whitelabel-config";
import { toast } from "react-toastify";
import { menu_data } from "../../data";
import student_menu_data from "../../data/student-menu-data";
import recuiter_menu_data from "../../data/recuiter-menu-data";
import mentor_menu_data from "../../data/mentor-menu-data";
import admin_menu_data from "../../data/admin-menu-data";

import { getDataFromLocalStorage } from "../../browser-storage";

import "./header.css";
// Get the full hostname (including subdomain)
let fullHostname = window.location.hostname;
//console.log("Current fullHostname:", fullHostname);
// Split the hostname into an array using dots as separators
let hostnameParts = fullHostname.split(".");

// The subdomain is the first part of the hostname
let subdomain = hostnameParts[0];

//console.log("Current subdomain:", subdomain);

export const LOGO_URL = `https://mygreenhorn.in/WhiteLabelAssets/${subdomain}/logo/logo.png?version=${Date.now()}`;
export const HERO_URL = `https://mygreenhorn.in/WhiteLabelAssets/${subdomain}/brochures/1.png?version=${Date.now()}`;
export const ABOUT_URL = `https://mygreenhorn.in/WhiteLabelAssets/${subdomain}/about/1.png?version=${Date.now()}`;
export const WHY_URL = `https://mygreenhorn.in/WhiteLabelAssets/${subdomain}/why/1.png?version=${Date.now()}`;

const Header = ({ header_white, header_shadow }) => {
  const [Hostname, setHostname] = useState();
  const { sticky } = useSticky();
  const dispatch = useDispatch();
  const [logoUrl, setLogoURL] = useState();
  const [LogoWidth, setLogoWidth] = useState(120);
  const [GuestMenuData, setGuestMenuData] = useState([]);
  const [StudentMenuData, setStudentMenuData] = useState([]);
  const [currentRole, setcurrentRole] = useState("GUEST");
  const [currentMenu, setcurrentMenu] = useState("menu_data");
  const [MainWebsiteURL, setMainWebsiteURL] = useState(null);
  const [socialnpayments, setSocialpayments] = useState({
    InstagramUrl: "",
    FacebookURL: "",
    YoutubeURL: "",
    LinkedInURL: "",
    IsBookingPaid: "",
    SessionBookingPrice: "",
    MentorCouponKey: "",
  });
  const _user = localStorage.getItem("user");

  const navigate = useNavigate();

  useEffect(() => {
    const Hostname = window.location.hostname;
    setHostname(Hostname);
    const res = getWhiteLabelConfig(Hostname)
      .then((data) => {
        //console.log(data);
        //dispatch(GET_WHITELABEL_CONFIG(data));
        console.log("Header data", data);
        const current_logo_url = data[0].logoURL;
        const LogoWidth = data[0].DynamicWH3;
        const MainWebsiteURL = data[0].DynamicWH4;
        const GuestMenuData = data[0].GuestMenuData;
        const StudentMenuData = data[0].StudentMenuData;
        const InstagramUrl = data[0].InstagramUrl;
        const FacebookURL = data[0].FacebookURL;
        const YoutubeURL = data[0].YoutubeURL;
        const LinkedInURL = data[0].LinkedInURL;
        const IsBookingPaid = data[0].IsBookingPaid;
        const SessionBookingPrice = data[0].SessionBookingPrice;
        const MentorCouponKey = data[0].MentorCouponKey;
        //console.log("GuestMenuData", GuestMenuData);
        //console.log("StudentMenuData", StudentMenuData);
        setLogoURL(current_logo_url);
        setLogoWidth(LogoWidth);
        setMainWebsiteURL(MainWebsiteURL);
        setSocialpayments({
          InstagramUrl: InstagramUrl,
          FacebookURL: FacebookURL,
          YoutubeURL: YoutubeURL,
          LinkedInURL: LinkedInURL,
          IsBookingPaid: IsBookingPaid,
          SessionBookingPrice: SessionBookingPrice,
          MentorCouponKey: MentorCouponKey,
        });
        //setGuestMenuData(GuestMenuData);
        //State Callback
        //Commentingon20Dec
        setGuestMenuData(GuestMenuData, () => {
          //console.log("GuestMenuData", GuestMenuData);
          console.log("GuestMenuData success");
        });
        setStudentMenuData(StudentMenuData, () => {
          //console.log("StudentMenuData", StudentMenuData);
          console.log("StudentMenuData success");
        });
        //setStudentMenuData(StudentMenuData);
      })
      .catch((err) =>
        // toast.error(`Unable to get White label Data. Contact US `, {
        //   position: "top-left",
        // })
        console.log("White Error", err)
      );
    setHostname(fullHostname);
    if (_user) {
      const user = JSON.parse(_user);
      const currentRoletemp = JSON.stringify(user.role);
      setcurrentRole(currentRoletemp);
    }
    console.log();
  }, [fullHostname]);

  useEffect(() => {
    const user = JSON.parse(_user);
    //console.log("user", user);
    if (user) {
      const currentRoletemp = user.role;
      //console.log("currentRole", currentRoletemp);
      setcurrentRole(currentRoletemp);
      if (currentRoletemp === "ADMIN") {
      } else if (currentRoletemp === "STUDENT") {
        setcurrentMenu(student_menu_data);
      }
    } else {
      //console.log("Reached Here");
      setcurrentRole("GUEST");
    }
  }, []);

  const handleLogout = () => {
    //console.log("button clicked");
    localStorage.removeItem("user");
    localStorage.removeItem("user-details");
    setcurrentRole("GUEST");
    navigate("/student-login", { replace: true });
  };

  const [isLoggedInStatus, setisLoggedInStatus] = useState(false);
  const [currentStudentDetails, setCurrentStudentDetails] = useState();
  const [currentSource, setCurrentSource] = useState();
  let checkLoginStatus = async () => {
    const userDetials = getDataFromLocalStorage("user-details");
    //console.log(userDetials);
    setCurrentStudentDetails(userDetials);
    let token = userDetials.token;
    console.log("token", token);
    if (token) {
      setisLoggedInStatus(true);
    }
    //console.log("isLoggedInStatus", isLoggedInStatus);
  };

  useEffect(() => {
    checkLoginStatus();
    //console.log("current href", window.location.host);
    setCurrentSource(window.location.host);
  }, []);
  return (
    <>
      <header>
        <div
          id="header-sticky"
          className={`header__area 
        ${
          header_shadow
            ? "header__padding-2 header__shadow"
            : "header__transparent header__padding"
        } 
        ${header_white ? "header__white" : ""} ${sticky ? "sticky" : ""}`}
        >
          <div className={`${header_shadow ? "container" : "container-fluid"}`}>
            <div className="row align-items-center">
              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-4 col-6">
                <div className="header__left d-flex">
                  <div className="desktop">
                    <div className="logo">
                      <Link to="/">
                        <img width={LogoWidth} src={LOGO_URL} alt="logo" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-10 col-sm-8 col-6">
                <div className="header__right d-flex justify-content-end align-items-center">
                  <div
                    className={`${
                      header_white
                        ? "main-menu main-menu-3 d-none d-xl-block"
                        : header_shadow
                        ? "main-menu main-menu-2 d-none d-xl-block"
                        : "main-menu d-none d-xl-block"
                    }`}
                  >
                    <nav id="mobile-menu">
                      {/* NavMenu start */}
                      {/* <NavMenu /> */}
                      {/* NavMenu end */}
                      {currentRole === "STUDENT" ? (
                        <ul>
                          {/* Social media icons */}
                          {socialnpayments.InstagramUrl ? (
                            <>
                              <li>
                                <a
                                  target="_blank"
                                  href={socialnpayments.InstagramUrl}
                                >
                                  <i className="fab fa-instagram"></i>
                                </a>
                              </li>
                            </>
                          ) : null}

                          {socialnpayments.FacebookURL ? (
                            <li>
                              <a
                                target="_blank"
                                href={socialnpayments.FacebookURL}
                              >
                                <i className="fab fa-facebook"></i>
                              </a>
                            </li>
                          ) : null}
                          {socialnpayments.LinkedInURL ? (
                            <li>
                              <a
                                target="_blank"
                                href={socialnpayments.LinkedInURL}
                              >
                                <i className="fab fa-linkedin"></i>
                              </a>
                            </li>
                          ) : null}
                          {socialnpayments.YoutubeURL ? (
                            <li>
                              <a
                                target="_blank"
                                href={socialnpayments.YoutubeURL}
                              >
                                <i className="fab fa-youtube"></i>
                              </a>
                            </li>
                          ) : null}
                          {/* {socialnpayments.FacebookURL ? :null}
                           */}
                          {StudentMenuData.map((link, index) => (
                            <li key={index} className="">
                              <Link to={`${link.MenuLink}`}>
                                {link.MenuTitle}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      ) : null}

                      {currentRole === "ADMIN" ? (
                        <ul>
                          {admin_menu_data.map((link, index) => (
                            <li
                              key={index}
                              className={link.submenu ? "has-dropdown" : ""}
                            >
                              <Link to={`${link.link}`}>{link.title}</Link>
                              {link.submenu && (
                                <ul className="submenu">
                                  {link.submenu.map((menu) => (
                                    <li key={menu.id}>
                                      <Link to={`${menu.link}`}>
                                        {menu.title}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      ) : null}

                      {currentRole === "EMPLOYER" ? (
                        <ul>
                          {recuiter_menu_data.map((link, index) => (
                            <li
                              key={index}
                              className={link.submenu ? "has-dropdown" : ""}
                            >
                              <Link to={`${link.link}`}>{link.title}</Link>
                              {link.submenu && (
                                <ul className="submenu">
                                  {link.submenu.map((menu) => (
                                    <li key={menu.id}>
                                      <Link to={`${menu.link}`}>
                                        {menu.title}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      ) : null}

                      {currentRole === "MENTOR" ? (
                        <ul>
                          {mentor_menu_data.map((link, index) => (
                            <li
                              key={index}
                              className={link.submenu ? "has-dropdown" : ""}
                            >
                              <Link to={`${link.link}`}>{link.title}</Link>
                              {link.submenu && (
                                <ul className="submenu">
                                  {link.submenu.map((menu) => (
                                    <li key={menu.id}>
                                      <Link to={`${menu.link}`}>
                                        {menu.title}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      ) : null}

                      {currentRole === "GUEST" ? (
                        <ul>
                          {/* Social media icons */}
                          {socialnpayments.InstagramUrl ? (
                            <>
                              <li>
                                <a
                                  target="_blank"
                                  href={socialnpayments.InstagramUrl}
                                >
                                  <i className="fab fa-instagram"></i>
                                </a>
                              </li>
                            </>
                          ) : null}

                          {socialnpayments.FacebookURL ? (
                            <li>
                              <a
                                target="_blank"
                                href={socialnpayments.FacebookURL}
                              >
                                <i className="fab fa-facebook"></i>
                              </a>
                            </li>
                          ) : null}
                          {socialnpayments.LinkedInURL ? (
                            <li>
                              <a
                                target="_blank"
                                href={socialnpayments.LinkedInURL}
                              >
                                <i className="fab fa-linkedin"></i>
                              </a>
                            </li>
                          ) : null}
                          {socialnpayments.YoutubeURL ? (
                            <li>
                              <a
                                target="_blank"
                                href={socialnpayments.YoutubeURL}
                              >
                                <i className="fab fa-youtube"></i>
                              </a>
                            </li>
                          ) : null}
                          {/* {socialnpayments.FacebookURL ? :null}
                           */}

                          {MainWebsiteURL ? (
                            <li>
                              <a href={MainWebsiteURL}>Home</a>
                            </li>
                          ) : null}

                          {GuestMenuData.map((link, index) => (
                            <li key={index} className="">
                              <Link to={`${link.MenuLink}`}>
                                {link.MenuTitle}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </nav>
                  </div>

                  {header_shadow && (
                    <div className="header__btn header__btn-2 ml-5 d-none d-sm-block">
                      {currentRole == "GUEST" ? (
                        <>
                          <Link
                            className="gradient-button ml-25"
                            to="/one-on-one-booking"
                          >
                            1:1 Call
                          </Link>

                          <Link to="/student-login" className="e-btn ml-25">
                            Sign in
                          </Link>
                        </>
                      ) : null}

                      {currentRole !== "GUEST" ? (
                        <div
                          onClick={() => handleLogout()}
                          className="e-btn ml-25"
                        >
                          Logout
                        </div>
                      ) : null}
                    </div>
                  )}
                  <div className="sidebar__menu d-xl-none">
                    <div
                      onClick={() => dispatch(menu_bar(true))}
                      className="sidebar-toggle-btn ml-30"
                      id="sidebar-toggle"
                    >
                      <span className="line"></span>
                      <span className="line"></span>
                      <span className="line"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* sidebar start */}
      <Sidebar currentRoleHeader={currentRole} />
      {/* sidebar end */}
    </>
  );
};

export default Header;
