import { createSlice } from "@reduxjs/toolkit";
import config from "../../services/config";
const initialState = {
  firstName: "",
  lastName: "",
  profession: "",
  location: "",
  profileImgUrl: "",
  coverImgUrl: "",
  ghScore: 0,
  academics: [],
  internships: [],
  certifications: [],
  languages: [],
  skills: [],
  dob: 0,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    GET_USER_PROFILE(state, action) {
      return {
        ...state,
        ...(action.data || {}),
        ...(action.data?.profileImgUrl
          ? {
              profileImgUrl: `${config.PROTOCOL}${config.IMAGE_HOST}/${action.data?.profileImgUrl}`,
            }
          : {}),
        ...(action.data?.coverImgUrl
          ? {
              coverImgUrl: `${config.PROTOCOL}${config.IMAGE_HOST}/${action.data?.coverImgUrl}`,
            }
          : {}),
      };
    },
    UPDATE_USER_PROFILE(state, action) {
      console.log("action", action.payload);
      return {
        ...state,
        ...(action.payload || {}),
        ...(action.payload?.profileImgUrl
          ? {
              profileImgUrl: `${config.PROTOCOL}${config.IMAGE_HOST}/${action.payload?.profileImgUrl}`,
            }
          : {}),
        ...(action.payload?.coverImgUrl
          ? {
              coverImgUrl: `${config.PROTOCOL}${config.IMAGE_HOST}/${action.payload?.coverImgUrl}`,
            }
          : {}),
      };
    },
    REMOVE_PROFILE_PIC(state, action) {
      return {
        ...state,
        profileImgUrl: "",
      };
    },
    REMOVE_COVER_PIC(state, action) {
      return {
        ...state,
        coverImgUrl: "",
      };
    },
    REMOVE_STUDENT_ROLE(state, action) {
      return {
        ...state,
        role: "",
      };
    },
    SET_ROLE_AS_STUDENT(state, action) {
      return {
        ...state,
        role: "STUDENT",
      };
    },
  },
});

export const {
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  REMOVE_PROFILE_PIC,
  REMOVE_COVER_PIC,
  REMOVE_STUDENT_ROLE,
  SET_ROLE_AS_STUDENT,
} = profileSlice.actions;

export default profileSlice.reducer;
