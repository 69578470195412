import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { useEffect, useState, useRef } from "react";

import { razorpayCreateOrderWithSplitPayment } from "../../services/payment-service";
import { addserviceScheduler } from "../../services/scheduler-service";
import { getDataFromLocalStorage } from "../../browser-storage";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

import {
  AddStudentCouponRecordService,
  checkIsCouponValid,
} from "../../services/coupon-service";
import "./homepaymentpage.css";
import JobsPopupLoginForm from "../../pages/jobs/jobs-popup-login-form";
import Modal from "react-modal";
import ReviewVideoGallery from "./review-video-gallery";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
//#whitelabel-home-payment
const HomePagePayment = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "600px",
    },
  };
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentSource, setCurrentSource] = useState();

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  const location = useLocation();
  const [selectedpackageTitle, setselectedpackageTitle] = useState("");
  const [selectedpackageSubTitle, setselectedpackageSubTitle] = useState("");
  const [selectedpackageFeatures, setselectedpackageFeatures] = useState([]);
  const [selectedpackageprice, setselectedpackageprice] = useState("");
  const [selectedActivatePackage, setActivatePackage] = useState("");
  const [isLoggedInStatus, setisLoggedInStatus] = useState(false);
  const [currentStudentDetails, setCurrentStudentDetails] = useState();

  let checkLoginStatus = async () => {
    const userDetials = getDataFromLocalStorage("user-details");
    console.log(userDetials);
    setCurrentStudentDetails(userDetials);
    let token = userDetials.token;
    console.log("token", token);
    if (token) {
      setisLoggedInStatus(true);
    }
    console.log("isLoggedInStatus", isLoggedInStatus);
  };

  useEffect(() => {
    checkLoginStatus();
    console.log("current href", window.location.host);
    setCurrentSource(window.location.host);
  }, []);

  useEffect(() => {
    const Data = location.state?.data;
    console.log("Data from pricing page", Data);
    setselectedpackageTitle(Data.PricingTitle);
    setselectedpackageSubTitle(Data.PricingSubTitle);
    setselectedpackageFeatures(Data.PricingFeatures);
    setselectedpackageprice(Data.PrincingPrice);
    setActivatePackage(Data.PricingActivatePackage);
    // setselectedpackageTitle(Data.PricingTitle);
    // setselectedpackageSubTitle(Data.PricingSubTitle);
    // setselectedpackageFeatures(Data.PricingFeatures);
    // setselectedpackageprice(Data.PrincingPrice);
    // setActivatePackage(Data.PricingActivatePackage);
  }, [selectedpackageFeatures]);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    console.log("selectedpackageprice", selectedpackageprice);

    if (discountvalue) {
      console.log("Entered on the discount loop");
      //Step 1 Find discount value
      const final_discount_value = Math.round(
        selectedpackageprice * (discountvaluefromresp / 100)
      );
      console.log("final_discount_value", final_discount_value);

      //Step 2 Find value after reducing the discount
      const getFinalValue = selectedpackageprice - final_discount_value;
      console.log("getFinalValue", getFinalValue);

      //Step 3 Find the Razorpay portal charges for price after discount
      const razorpayWIthGST = Number(getFinalValue * 2.7) / 100;
      console.log("razorpayWIthGST", razorpayWIthGST);

      //Step 4 Add Razorpay portal charges upfront
      const Price_with_razorpay_upfront = getFinalValue + razorpayWIthGST;
      console.log("Price_with_razorpay_upfront", Price_with_razorpay_upfront);

      //Step 5 Mentor Share
      //dont add razorpay value in this
      const mentor_share = Number(getFinalValue * 88.2) / 100;

      const payload = {
        amount: Number(Price_with_razorpay_upfront) * 100,
        discount_code_used: coupondetails.coupon_code_submitted,
        mygreenhorn_amount: "",
        mentor_amount: Number(mentor_share) * 100,
        //amount: 100,
      };
      console.log("payload", payload);
      const result = await razorpayCreateOrderWithSplitPayment(payload)
        .then((res) => {
          console.log(res);
          let response = res;
          const id = response.id;
          const options = {
            currency: "INR",
            name: "GreenHorn",
            order_id: id,
            description: "GreenHorn Transaction",
            handler: async function (response) {
              const data = {
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
              };
              console.log(response);
              const userDetials = getDataFromLocalStorage("user-details");
              console.log(userDetials);
              const email = userDetials.email;
              const payload = {
                username: userDetials.userName,
                servicebooked: "Homepage Payment Packages",
                bookingDate: new Date(),
                amount: selectedpackageprice,
                source: currentSource,
                discount_code_used: coupondetails.coupon_code_submitted,
                mentor_amount: Number(mentorSplitAmount * 88.2) / 100,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
              };
              const schedulerResult = await addserviceScheduler(payload)
                .then((res) => {
                  console.log(res);
                })
                .catch((err) => {
                  console.log(err);
                });
              const coupon_payload = {
                mentor_coupon_code: coupondetails.coupon_code_submitted,
                mentor_name: "",
                mentorEmailId: "",
                generation_date: new Date(),
                StudentEmailId: currentStudentDetails.userName,
                StudentName: currentStudentDetails.name,
                PackageSelected: selectedpackageprice,
                ActivatePackage: selectedActivatePackage,
              };
              const Couponrecordresult = await AddStudentCouponRecordService(
                coupon_payload
              )
                .then((res) => {
                  console.log(res);
                })
                .catch((err) => {
                  console.log(err);
                });

              console.log(schedulerResult);
              console.log("Couponrecordresult", Couponrecordresult);
              if (response) {
                toast.success(
                  `Payment Successfully Completed, Please check your mail for more detials `,
                  {
                    position: "top-left",
                  }
                );
              }
            },
            theme: {
              color: "#61dafb",
            },
          };

          const paymentObject = new window.Razorpay(options);

          paymentObject.open();
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(result);
    }
  }

  const [coupondetails, setCouponDetails] = useState({
    coupon_code_submitted: "",
  });

  const [discountvalue, setDiscountvalue] = useState();
  const [discountvaluefromresp, setDiscountvaluefromresp] = useState();

  let couponDetailsonChange = (evt) => {
    console.log("evt", evt.target.value);
    setCouponDetails({
      ...coupondetails,
      [evt.target.name]: evt.target.value,
    });
  };

  const [mentorSplitAmount, setMentorSplitAmount] = useState();
  const [discountValuetoshow, setDiscountvaluetoshow] = useState();

  const checkIsCouponValidfunction = async () => {
    const couponobj = {
      studentEmailId: "",
      coupon_code: coupondetails.coupon_code_submitted,
      package_selected: "",
    };
    const checkresult = await checkIsCouponValid(couponobj)
      .then((resp) => {
        const checkresp = resp.checkresult;
        //Step 1 Find discount value
        const discount_value = checkresp.discount_value;
        console.log("discount_value", discount_value);
        setDiscountvaluefromresp(discount_value);
        const final_discount_value = Math.round(
          selectedpackageprice * (discount_value / 100)
        );
        console.log("final_discount_value", final_discount_value);

        //Step 2 Find value after reducing the discount
        const getFinalValue = selectedpackageprice - final_discount_value;
        console.log("getFinalValue", getFinalValue);

        setDiscountvaluetoshow(getFinalValue);

        //Step 3 Find the Razorpay portal charges for price after discount
        const razorpayWIthGST = Number(getFinalValue * 2.7) / 100;
        console.log("razorpayWIthGST", razorpayWIthGST);

        //Step 4 Add Razorpay portal charges upfront
        const Price_with_razorpay_upfront = getFinalValue + razorpayWIthGST;
        console.log("Price_with_razorpay_upfront", Price_with_razorpay_upfront);
        //Step 5 Find the GST cost for MygreenHorn with razorpay charges

        setMentorSplitAmount(getFinalValue);

        //Step 6 Final Costing with all above
        // const getFinalValueWithGST = Price_with_razorpay_upfront + GSTCost;
        const getFinalValueWithGST = Price_with_razorpay_upfront;
        console.log("getFinalValueWithGST", getFinalValueWithGST);

        setDiscountvalue(getFinalValueWithGST);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const inputRef = useRef(null);

  const handlePaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text/plain");
    const textWithoutSpaces = pastedText.replace(/\s/g, ""); // Remove spaces
    document.execCommand("insertText", false, textWithoutSpaces);
  };
  return (
    <Wrapper>
      <SEO pageTitle={"Homepage Payment"} />
      <>
        <Container style={{ backgroundColor: "white" }}>
          <Row style={{ marginBottom: "20px" }}>
            <Card className="carddesign">
              <Card.Body>
                <Row>
                  <Col sm={8}>
                    <img src="/assets/img/ghimages/1.jpg" />
                  </Col>
                  <Col>
                    <div
                      className="price__item grey-bg mb-30 p-relative"
                      style={{ padding: "20px" }}
                    >
                      <div className="price__head">
                        <h4>{selectedpackageTitle}</h4>
                      </div>

                      <div className="price__features mb-40">
                        {!discountvalue ? (
                          <div className="price__tag mb-25">
                            <h4>
                              ₹ {selectedpackageprice}
                              <span style={{ fontSize: 16 }}>
                                &nbsp;(+ 2.7% Online Transaction fee)
                              </span>
                            </h4>
                          </div>
                        ) : (
                          <div className="price__tag mb-25">
                            <div className="style-1">
                              <del>
                                <span className="amount">
                                  ₹ {selectedpackageprice}
                                </span>
                              </del>
                              <ins>
                                <span className="amount">
                                  ₹ {discountValuetoshow}
                                </span>
                              </ins>
                              <br />
                              <span style={{ fontSize: 16 }}>
                                &nbsp;(+ 2.7% Online Transaction fee)
                              </span>
                            </div>
                          </div>
                        )}
                        <ul>
                          {selectedpackageFeatures.map(
                            (feature, index) =>
                              feature !== "" && (
                                <li key={index}>
                                  <i className="far fa-check"></i>
                                  {feature.value}
                                </li>
                              )
                          )}
                        </ul>
                      </div>

                      <p>Customize your package based on your needs</p>

                      {isLoggedInStatus && !discountvalue && (
                        <div className="text-center">
                          <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                              <Tooltip {...props}>
                                Please use mentor coupon code to proceed
                              </Tooltip>
                            )}
                            placement="bottom"
                          >
                            <Button className="e-btn e-btn-border">
                              Book Now
                            </Button>
                          </OverlayTrigger>
                        </div>
                      )}

                      {isLoggedInStatus && discountvalue && (
                        <div className="text-center">
                          <button
                            className="e-btn btn-success"
                            style={{ marginTop: "5px", background: "green" }}
                            onClick={displayRazorpay}
                          >
                            Book Now
                          </button>
                        </div>
                      )}

                      <p>
                        <span style={{ color: "black", fontSize: 18 }}>
                          Final Price :{" "}
                        </span>
                        <span style={{ color: "green", fontSize: 18 }}>
                          {discountvalue}
                        </span>{" "}
                        (
                        <span style={{ color: "green" }}>
                          {discountValuetoshow}
                        </span>{" "}
                        add 2.7% Transaction fee)
                      </p>

                      <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                        overlayClassName="Overlay"
                      >
                        <JobsPopupLoginForm />
                      </Modal>

                      {!isLoggedInStatus && (
                        <div className="text-center">
                          <button
                            className="e-btn e-btn-border"
                            style={{ marginTop: "5px" }}
                            onClick={openModal}
                          >
                            Login
                          </button>
                        </div>
                      )}

                      {!isLoggedInStatus && (
                        <p style={{ color: "red" }}>
                          * Please Login to Complete Payment
                        </p>
                      )}

                      {isLoggedInStatus && (
                        <>
                          <p>Have a Coupon code ?</p>
                          <input
                            placeholder="Coupon Code"
                            ref={inputRef}
                            onPaste={handlePaste}
                            onKeyDown={(e) => {
                              if (e.key === " " || e.keyCode === 32) {
                                e.preventDefault();
                              }
                            }}
                            className="form-control marginTop5"
                            type="text"
                            name="coupon_code_submitted"
                            value={coupondetails.coupon_code_submitted}
                            onChange={couponDetailsonChange}
                          />
                          <button
                            className="e-btn e-btn-border"
                            style={{ marginTop: "5px" }}
                            onClick={checkIsCouponValidfunction}
                          >
                            Apply Now
                          </button>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Row>
        </Container>
      </>
    </Wrapper>
  );
};

export default HomePagePayment;
