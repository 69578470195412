import React, { useRef, useEffect, useState } from "react";
import "../resumebuilder/resumebuilder.css";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Badge from "react-bootstrap/Badge";
import { useReactToPrint } from "react-to-print";
import {
  setDataInLocalStorage,
  getDataFromLocalStorage,
} from "../../browser-storage";
import axios from "axios";
import config from "../../services/config";
import { toast } from "react-toastify";
import Accordion from "react-bootstrap/Accordion";
import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { BasicDetailsFieldNames } from "./InputField";
import "./resumebuilder.css";
import {
  MonthNumberArray,
  YearArray,
  CirlceRow,
} from "./resume-builder-functions";

import { useFormHandlers } from "./FormHandlers";
import ResumeProfileImg from "./resumeProfileImg";

const ResumeBuilderToolOptionSix = () => {
  const componentRef = useRef();

  let handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => getSavedData(), []);
  const [profileImgUrl, setprofileImgUrl] = useState();
  //for resume profile picture update
  const handleChange = (newValue) => {
    console.log("State of ABC component changed:", newValue);
    getSavedData(); // Call runthis function when the state of ABC changes
  };

  let getSavedData = () => {
    const userDetails = getDataFromLocalStorage("user");
    const CreatedBy = { CreatedBy: userDetails.email };

    axios
      .post(`${config.PROTOCOL}${config.HOST}/resume/get-resume`, CreatedBy)
      .then(function (response) {
        const { data: resumeResp } = response;

        if (resumeResp) {
          setDataInLocalStorage("savedResumeData", resumeResp);

          const SavedResume = getDataFromLocalStorage("savedResumeData");
          const {
            BasicDetails,
            Education,
            WorkExprience,
            Certification,
            Aboutme,
            Skills,
            Languages,
            Awards,
            profileImgUrl,
          } = SavedResume;

          setBasicDetails(BasicDetails);
          setEducation(Education);
          setCertification(Certification);
          if (WorkExprience) {
            setWorkExprience(WorkExprience);
          }
          setAboutme(Aboutme);
          setSkills(Skills);
          setLanguages(Languages);
          setAwards(Awards);
          setprofileImgUrl(profileImgUrl);
          console.log(SavedResume);
        } else {
          toast.error(`You don't have a saved Resume yet`, {
            position: "top-left",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let savepdf = () => {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    const email = userDetails.email;

    const resumedata = {
      BasicDetails,
      Education,
      Certification,
      WorkExprience,
      Aboutme,
      Skills,
      Languages,
      Awards,
      CreatedBy: { CreatedBy: email },
    };

    setDataInLocalStorage("savedResumeData", resumedata);

    axios
      .put(`${config.PROTOCOL}${config.HOST}/resume/resume-builder`, resumedata)
      .then(function (response) {
        console.log(response);
        toast.success(`Data Successfully Saved`, { position: "top-left" });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const {
    BasicDetails,
    Aboutme,
    WorkExprience,
    Education,
    Certification,
    Skills,
    Languages,
    Awards,
    setBasicDetails,
    setAboutme,
    setWorkExprience,
    setEducation,
    setCertification,
    setSkills,
    setLanguages,
    setAwards,
    BasicDetailsonChange,
    AboutmeonChange,
    EducationhandleChange,
    addEducationFormFields,
    removeEducationFormFields,
    WorkExphandleInputChange,
    WorkExphandleSubInputChange,
    WorkExpaddInputField,
    WorkExpaddSubInputField,
    WorkExpremoveInputField,
    WorkExpremoveSubInputField,
    CertificationhandleChange,
    CertificationaddFormFields,
    CertificationremoveFormFields,
    SkillshandleChange,
    addSkillsField,
    removeSkillsField,
    LanguagehandleChange,
    addLanguageField,
    removeLanguageField,
    AwardsshandleChange,
    addAwardField,
    removeAwardsField,
  } = useFormHandlers();

  const [descriptionfontsize, setdescriptionfontsize] = useState(11);
  const [titlefontsize, settitlefontsize] = useState(13);

  // Variable value out of 5
  const value = 3;

  // Fill circles based on the value
  const circles = Array.from(Array(5), (_, index) => index < value);

  return (
    <Wrapper>
      <SEO pageTitle={"Resume Builder Tool"} />
      <Row>
        <Accordion
          style={{ marginTop: "20px" }}
          defaultActiveKey={["0"]}
          alwaysOpen
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header>Advanced Customizations</Accordion.Header>
            <Accordion.Body>
              <Card>
                <div>
                  <div style={{ padding: 10 }}>
                    Change Title Font Size &nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() => settitlefontsize(titlefontsize - 1)}
                    >
                      -
                    </button>
                    &nbsp; [{titlefontsize}] &nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() => settitlefontsize(titlefontsize + 1)}
                    >
                      +
                    </button>
                  </div>
                  <div style={{ padding: 10 }}>
                    Change Description Font Size &nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        setdescriptionfontsize(descriptionfontsize - 1)
                      }
                    >
                      -
                    </button>
                    &nbsp; [{descriptionfontsize}] &nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        setdescriptionfontsize(descriptionfontsize + 1)
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Col className="divscroll" sm={4}>
          <div style={{ margin: 10, color: "#000000" }}>
            <Badge style={{ width: "100%" }} bg="secondary">
              Form
            </Badge>
            <Card className="carddesign">
              <Card.Body>
                <Card.Text>
                  <div className="MobTextStyle">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>BASIC DETAILS</Accordion.Header>
                        <Accordion.Body>
                          <div className="MobTextStyle">
                            <input
                              placeholder="First Name"
                              className="form-control marginTop5"
                              type="text"
                              name="firstname"
                              value={BasicDetails.firstname}
                              onChange={BasicDetailsonChange}
                            />
                            <input
                              placeholder="Middle Name"
                              className="form-control marginTop5"
                              type="text"
                              name="middlename"
                              value={BasicDetails.middlename}
                              onChange={BasicDetailsonChange}
                            />
                            <input
                              placeholder="Last Name"
                              className="form-control marginTop5"
                              type="text"
                              name="lastname"
                              value={BasicDetails.lastname}
                              onChange={BasicDetailsonChange}
                            />
                            <input
                              placeholder="Degree"
                              className="form-control marginTop5"
                              type="text"
                              name="degree"
                              value={BasicDetails.degree}
                              onChange={BasicDetailsonChange}
                            />
                            <input
                              placeholder="Phone"
                              className="form-control marginTop5"
                              type="text"
                              name="phonenumber"
                              value={BasicDetails.phonenumber}
                              onChange={BasicDetailsonChange}
                            />
                            <input
                              placeholder="Email"
                              className="form-control marginTop5"
                              type="text"
                              name="email"
                              value={BasicDetails.email}
                              onChange={BasicDetailsonChange}
                            />
                            <input
                              placeholder="City"
                              className="form-control marginTop5"
                              type="text"
                              name="city"
                              value={BasicDetails.city}
                              onChange={BasicDetailsonChange}
                            />
                            <input
                              placeholder="Pin Code"
                              className="form-control marginTop5"
                              type="number"
                              name="pincode"
                              value={BasicDetails.pincode}
                              onChange={BasicDetailsonChange}
                            />
                            <input
                              placeholder="Linkedin"
                              className="form-control marginTop5"
                              type="text"
                              name="linkedin"
                              value={BasicDetails.linkedin}
                              onChange={BasicDetailsonChange}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="carddesign marginTop5">
              <Card.Body>
                <Card.Text>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>EDUCATION</Accordion.Header>
                      <Accordion.Body>
                        <p style={{ color: "#ffc107", fontWeight: 600 }}>
                          Note: Start with your latest education
                        </p>
                        {Education.map((element, index) => (
                          <div className="form-inline" key={index}>
                            <Row className="marginTop5">
                              <Col>
                                <select
                                  name="startMonth"
                                  className="form-control"
                                  value={Education.startMonth}
                                  onChange={(e) =>
                                    EducationhandleChange(index, e)
                                  }
                                  placeholder="StartMonth"
                                >
                                  <option
                                    name=""
                                    value=""
                                    style={{ display: "none" }}
                                  >
                                    Start Month
                                  </option>

                                  {MonthNumberArray.map((element) => {
                                    return (
                                      <>
                                        <option
                                          name={element.name}
                                          value={element.value}
                                        >
                                          {element.name}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </Col>
                              <Col>
                                <select
                                  name="startYear"
                                  className="form-control"
                                  value={Education.startYear}
                                  onChange={(e) =>
                                    EducationhandleChange(index, e)
                                  }
                                  placeholder="StartYear"
                                >
                                  <option
                                    name=""
                                    value=""
                                    style={{ display: "none" }}
                                  >
                                    Start Year
                                  </option>
                                  {YearArray.map((element) => {
                                    return (
                                      <>
                                        <option name={element} value={element}>
                                          {element}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </Col>
                            </Row>
                            <Row className="marginTop5">
                              <Col>
                                <select
                                  name="endMonth"
                                  className="form-control"
                                  value={element.endMonth}
                                  onChange={(e) =>
                                    EducationhandleChange(index, e)
                                  }
                                  placeholder="EndMonth"
                                >
                                  <option
                                    name=""
                                    value=""
                                    style={{ display: "none" }}
                                  >
                                    End Month
                                  </option>
                                  {MonthNumberArray.map((element) => {
                                    return (
                                      <>
                                        <option
                                          name={element.name}
                                          value={element.value}
                                        >
                                          {element.name}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </Col>
                              <Col>
                                <select
                                  name="endYear"
                                  className="form-control"
                                  value={element.endYear}
                                  onChange={(e) =>
                                    EducationhandleChange(index, e)
                                  }
                                  placeholder="EndYear"
                                >
                                  <option
                                    name=""
                                    value=""
                                    style={{ display: "none" }}
                                  >
                                    End Year
                                  </option>
                                  {YearArray.map((element) => {
                                    return (
                                      <>
                                        <option name={element} value={element}>
                                          {element}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </Col>
                            </Row>
                            <input
                              placeholder="School / College Name"
                              className="form-control"
                              type="text"
                              name="collegeName"
                              value={element.collegeName || ""}
                              onChange={(e) => EducationhandleChange(index, e)}
                            />
                            <input
                              placeholder="Class / Degree Name"
                              className="form-control"
                              type="text"
                              name="degreeName"
                              value={element.degreeName || ""}
                              onChange={(e) => EducationhandleChange(index, e)}
                            />
                            <input
                              placeholder="Board / Course Name"
                              className="form-control"
                              type="text"
                              name="courseName"
                              value={element.courseName || ""}
                              onChange={(e) => EducationhandleChange(index, e)}
                            />
                            <input
                              placeholder="Score"
                              className="form-control"
                              type="text"
                              name="score"
                              value={element.score || ""}
                              onChange={(e) => EducationhandleChange(index, e)}
                            />
                            {index ? (
                              <button
                                type="button"
                                className="btn btn-primary button remove"
                                onClick={() => removeEducationFormFields(index)}
                              >
                                Remove
                              </button>
                            ) : null}
                          </div>
                        ))}
                        <div className="button-section">
                          <button
                            className="btn btn-primary button add"
                            type="button"
                            onClick={() => addEducationFormFields()}
                          >
                            Add
                          </button>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="carddesign marginTop5">
              <Card.Body>
                <Card.Text>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>WORK EXPERIENCE</Accordion.Header>
                      <Accordion.Body>
                        <p style={{ color: "#ffc107", fontWeight: 600 }}>
                          Note: Start with your latest work experience
                        </p>
                        {WorkExprience.map((field, index) => (
                          <div key={index}>
                            <div className="row">
                              <div className="col-md-6">
                                <select
                                  name="startMonth"
                                  className="form-control dateHeight"
                                  value={field.startMonth}
                                  onChange={(event) =>
                                    WorkExphandleInputChange(index, event)
                                  }
                                  placeholder="StartMonth"
                                >
                                  <option
                                    name=""
                                    value=""
                                    style={{ display: "none" }}
                                  >
                                    Start Month
                                  </option>
                                  {MonthNumberArray.map((element) => {
                                    return (
                                      <>
                                        <option
                                          name={element.name}
                                          value={element.value}
                                        >
                                          {element.name}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-md-6">
                                <select
                                  name="startYear"
                                  className="form-control dateHeight"
                                  value={field.startYear}
                                  onChange={(event) =>
                                    WorkExphandleInputChange(index, event)
                                  }
                                  placeholder="StartYear"
                                >
                                  <option
                                    name=""
                                    value=""
                                    style={{ display: "none" }}
                                  >
                                    Start Year
                                  </option>
                                  {YearArray.map((element) => {
                                    return (
                                      <>
                                        <option name={element} value={element}>
                                          {element}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-md-6">
                                <select
                                  name="endMonth"
                                  className="form-control"
                                  value={field.endMonth}
                                  onChange={(event) =>
                                    WorkExphandleInputChange(index, event)
                                  }
                                  placeholder="EndMonth"
                                >
                                  <option
                                    name=""
                                    value=""
                                    style={{ display: "none" }}
                                  >
                                    End Month
                                  </option>
                                  {MonthNumberArray.map((element) => {
                                    return (
                                      <>
                                        <option
                                          name={element.name}
                                          value={element.value}
                                        >
                                          {element.name}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-md-6">
                                <select
                                  name="endYear"
                                  className="form-control"
                                  value={field.endYear}
                                  onChange={(event) =>
                                    WorkExphandleInputChange(index, event)
                                  }
                                  placeholder="EndYear"
                                >
                                  <option
                                    name=""
                                    value=""
                                    style={{ display: "none" }}
                                  >
                                    End Year
                                  </option>
                                  {YearArray.map((element) => {
                                    return (
                                      <>
                                        <option name={element} value={element}>
                                          {element}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>

                            <input
                              placeholder="Company Name"
                              className="form-control"
                              type="text"
                              name="companyName"
                              value={field.companyName}
                              onChange={(event) =>
                                WorkExphandleInputChange(index, event)
                              }
                            />
                            <input
                              placeholder="Location"
                              className="form-control"
                              type="text"
                              name="location"
                              value={field.location}
                              onChange={(event) =>
                                WorkExphandleInputChange(index, event)
                              }
                            />
                            <input
                              placeholder="Designation Name"
                              className="form-control"
                              type="text"
                              name="designationName"
                              value={field.designationName}
                              onChange={(event) =>
                                WorkExphandleInputChange(index, event)
                              }
                            />
                            {field.description.map((subField, subIndex) => (
                              <div key={subIndex}>
                                <div className="row">
                                  <div
                                    style={{ padding: 2 }}
                                    className="col-md-10"
                                  >
                                    {" "}
                                    <textarea
                                      placeholder="Add Work Tasks"
                                      className="form-control"
                                      id="description"
                                      name="description"
                                      rows="10"
                                      cols="35"
                                      value={subField.value}
                                      onChange={(event) =>
                                        WorkExphandleSubInputChange(
                                          index,
                                          subIndex,
                                          event
                                        )
                                      }
                                    ></textarea>
                                  </div>
                                  <div
                                    style={{ padding: 3, marginTop: 5 }}
                                    className="col-md-2"
                                  >
                                    <button
                                      type="button"
                                      className="e-btn"
                                      style={{
                                        color: "white",
                                        background: "red",
                                      }}
                                      onClick={() =>
                                        WorkExpremoveSubInputField(
                                          index,
                                          subIndex
                                        )
                                      }
                                    >
                                      X
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="row">
                              <div className="col-md-6">
                                <button
                                  className="e-btn"
                                  type="button"
                                  style={{
                                    height: 75,
                                    lineHeight: 1,
                                    color: "white",
                                    padding: 5,
                                  }}
                                  onClick={() => WorkExpaddSubInputField(index)}
                                >
                                  Add work experience bullets
                                </button>
                              </div>
                              <div className="col-md-6">
                                <button
                                  className="e-btn"
                                  style={{
                                    color: "white",
                                    background: "red",
                                    height: 75,
                                    lineHeight: 1,
                                    padding: 5,
                                  }}
                                  type="button"
                                  onClick={() => WorkExpremoveInputField(index)}
                                >
                                  Delete work experience
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                        <button
                          type="button"
                          className="e-btn"
                          style={{ marginTop: 5 }}
                          onClick={() =>
                            setWorkExprience([
                              ...WorkExprience,
                              {
                                startYear: "",
                                startMonth: "",
                                endYear: "",
                                endMonth: "",
                                companyName: "",
                                designationName: "",
                                duration: "",
                                description: [],
                              },
                            ])
                          }
                        >
                          Add next work experience
                        </button>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="carddesign marginTop5">
              <Card.Body>
                <Card.Text>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>CERTIFICATION</Accordion.Header>
                      <Accordion.Body>
                        {Certification.map((element, index) => (
                          <div className="form-inline" key={index}>
                            <input
                              placeholder="Certificate Name"
                              className="form-control"
                              type="text"
                              name="certificateName"
                              value={element.certificateName}
                              onChange={(e) =>
                                CertificationhandleChange(index, e)
                              }
                            />
                            {/* <input
                              placeholder="Certificate Source"
                              className="form-control"
                              type="text"
                              name="certificateSourceName"
                              value={element.certificateSourceName}
                              onChange={(e) =>
                                CertificationhandleChange(index, e)
                              }
                            />
                            <input
                              placeholder="Certificate Link"
                              className="form-control"
                              type="text"
                              name="certificateLink"
                              value={element.certificateLink}
                              onChange={(e) =>
                                CertificationhandleChange(index, e)
                              }
                            /> */}
                            {index ? (
                              <button
                                type="button"
                                className="btn btn-primary button remove"
                                onClick={() =>
                                  CertificationremoveFormFields(index)
                                }
                              >
                                Remove
                              </button>
                            ) : null}
                          </div>
                        ))}
                        <div className="button-section">
                          <button
                            className="btn btn-primary button add"
                            type="button"
                            onClick={() => CertificationaddFormFields()}
                          >
                            Add
                          </button>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="carddesign marginTop5">
              <Card.Body>
                <Card.Text>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>ABOUT ME</Accordion.Header>
                      <Accordion.Body>
                        <textarea
                          id="Aboutme"
                          rows="10"
                          cols="35"
                          className="form-control"
                          value={Aboutme.Aboutme}
                          onChange={(e) => AboutmeonChange(e)}
                        ></textarea>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="carddesign marginTop5">
              <Card.Body>
                <Card.Text>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>SKILLS</Accordion.Header>
                      <Accordion.Body>
                        {Skills.map((element, index) => (
                          <div className="form-inline" key={index}>
                            <Row className="marginTop5">
                              <Col>
                                <input
                                  placeholder="Skill Name"
                                  className="form-control"
                                  type="text"
                                  name="skillname"
                                  value={element.skillname}
                                  onChange={(e) => SkillshandleChange(index, e)}
                                />
                              </Col>
                            </Row>
                            {index ? (
                              <button
                                type="button"
                                className="btn btn-primary button remove"
                                onClick={() => removeSkillsField(index)}
                              >
                                Remove
                              </button>
                            ) : null}
                          </div>
                        ))}
                        <div className="button-section">
                          <button
                            className="btn btn-primary button add"
                            type="button"
                            onClick={() => addSkillsField()}
                          >
                            Add
                          </button>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="carddesign marginTop5">
              <Card.Body>
                <Card.Text>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>LANGUAGES</Accordion.Header>
                      <Accordion.Body>
                        {Languages.map((element, index) => (
                          <div className="form-inline" key={index}>
                            <Row className="marginTop5">
                              <Col>
                                <input
                                  placeholder="Languages"
                                  className="form-control"
                                  type="text"
                                  name="langugename"
                                  value={element.langugename}
                                  onChange={(e) =>
                                    LanguagehandleChange(index, e)
                                  }
                                />
                              </Col>
                              <Col>
                                <select
                                  name="languagerating"
                                  className="form-control"
                                  value={element.languagerating}
                                  onChange={(e) =>
                                    LanguagehandleChange(index, e)
                                  }
                                  placeholder="Language Rating"
                                >
                                  <option
                                    name=""
                                    value=""
                                    style={{ display: "none" }}
                                  >
                                    Rate Yourself
                                  </option>
                                  <option name="1" value="1">
                                    1
                                  </option>
                                  <option name="2" value="2">
                                    2
                                  </option>
                                  <option name="3" value="3">
                                    3
                                  </option>
                                  <option name="4" value="4">
                                    4
                                  </option>
                                  <option name="5" value="5">
                                    5
                                  </option>
                                </select>
                              </Col>
                            </Row>
                            {index ? (
                              <button
                                type="button"
                                className="btn btn-primary button remove"
                                onClick={() => removeLanguageField(index)}
                              >
                                Remove
                              </button>
                            ) : null}
                          </div>
                        ))}
                        <div className="button-section">
                          <button
                            className="btn btn-primary button add"
                            type="button"
                            onClick={() => addLanguageField()}
                          >
                            Add
                          </button>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="carddesign marginTop5">
              <Card.Body>
                <Card.Text>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        AWARDS and ACHIVEMENTS
                      </Accordion.Header>
                      <Accordion.Body>
                        {Awards.map((element, index) => (
                          <div className="form-inline" key={index}>
                            <Row className="marginTop5">
                              <input
                                placeholder="Awards"
                                className="form-control"
                                type="text"
                                name="awardname"
                                value={element.awardname}
                                onChange={(e) => AwardsshandleChange(index, e)}
                              />
                            </Row>
                            {index ? (
                              <button
                                type="button"
                                className="btn btn-primary button remove"
                                onClick={() => removeAwardsField(index)}
                              >
                                Remove
                              </button>
                            ) : null}
                          </div>
                        ))}
                        <div className="button-section">
                          <button
                            className="btn btn-primary button add"
                            type="button"
                            onClick={() => addAwardField()}
                          >
                            Add
                          </button>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="carddesign marginTop5 marginBottom20">
              <Card.Body>
                <Card.Text>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>PROFILE PICTURE</Accordion.Header>
                      <Accordion.Body>
                        <div className="ProfileContainer">
                          <ResumeProfileImg handleChange={handleChange} />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Col>

        <Col sm={8}>
          <div style={{ margin: 10, marginBottom: 30, color: "#000000" }}>
            <Container>
              <Row>
                <Col>
                  <button
                    style={{
                      height: "25px",
                      width: "100%",
                      marginTop: "2px",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                    className="buttondesign"
                    onClick={getSavedData}
                  >
                    Get Saved Data <i class="fas fa-file-pdf"></i>
                  </button>
                </Col>
                <Col>
                  <button
                    style={{
                      height: "25px",
                      width: "100%",
                      marginTop: "2px",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                    className="buttondesign"
                    onClick={savepdf}
                  >
                    Save PDF <i class="fa fa-save"></i>
                  </button>
                </Col>
                <Col>
                  <button
                    style={{
                      height: "25px",
                      width: "100%",
                      marginTop: "2px",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                    className="buttondesign"
                    onClick={handlePrint}
                  >
                    Download PDF <i class="fas fa-download"></i>
                  </button>
                </Col>
              </Row>
            </Container>

            <div style={{ marginTop: 20 }} className="App" id="App">
              <div ref={componentRef}>
                <div
                  id="toprint"
                  style={{
                    marginLeft: "15px",
                    marginRight: "15px",
                    border: "0px",
                  }}
                  className="toprint"
                >
                  <div className="row">
                    <div
                      className="row"
                      style={{ display: "flex", flex: "1 -1 0%" }}
                    >
                      <div style={{ flex: "1" }}>
                        {profileImgUrl ? (
                          <div className="op5-profile">
                            <img
                              className="op5-profile"
                              src={`${config.PROTOCOL}${config.IMAGE_HOST}/${profileImgUrl}`}
                              alt=""
                            />
                          </div>
                        ) : (
                          <div className="op5-profile">
                            <img
                              className="op5-profile"
                              src="/assets/img/ghimages/blankprofile.png"
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                      <div style={{ flex: "5", borderRadius: "15px" }}>
                        <div>
                          <h2
                            style={{
                              margin: "0px",
                              fontWeight: 600,
                              fontSize: 24,
                            }}
                          >
                            {BasicDetails.firstname} {BasicDetails.middlename}{" "}
                            {BasicDetails.lastname}
                          </h2>
                          <h3
                            style={{
                              margin: "0px",
                              fontWeight: 600,
                              fontSize: 20,
                            }}
                            className="op5-date"
                          >
                            {BasicDetails.degree}
                          </h3>
                          <p
                            className="descriptionfontsize aboutmeresume"
                            style={{
                              margin: "0px",
                              fontSize: `${descriptionfontsize}px`,
                              color: "black",
                            }}
                          >
                            {Aboutme.Aboutme}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ background: "#ededed", marginTop: 10 }}
                    className="row bg-lightgray d-flex justify-content-between"
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ flex: "4" }}
                    >
                      {!BasicDetails.email ? (
                        BasicDetails.email
                      ) : (
                        <i
                          className="fa fa-envelope op5-icon"
                          aria-hidden="true"
                        ></i>
                      )}
                      &nbsp;
                      <div className="descriptionfontsize ml-2">
                        {BasicDetails.email}
                      </div>
                    </div>

                    <div
                      className="d-flex align-items-center"
                      style={{ flex: "2" }}
                    >
                      {!BasicDetails.phonenumber ? (
                        BasicDetails.phonenumber
                      ) : (
                        <i
                          className="fa fa-phone-flip op5-icon"
                          aria-hidden="true"
                        ></i>
                      )}
                      &nbsp;
                      <div className="descriptionfontsize ml-2">
                        {BasicDetails.phonenumber}
                      </div>
                    </div>

                    <div
                      className="d-flex align-items-center"
                      style={{ flex: "2" }}
                    >
                      {!BasicDetails.city ? (
                        BasicDetails.city
                      ) : (
                        <i
                          className="fa fa-location-dot op5-icon"
                          aria-hidden="true"
                        ></i>
                      )}
                      &nbsp;
                      <div className="descriptionfontsize ml-2">
                        {BasicDetails.city}
                        {BasicDetails.pincode !== "" ? "," : null}&nbsp;
                        {BasicDetails.pincode}
                      </div>
                    </div>

                    <div
                      className="d-flex align-items-center"
                      style={{ flex: "4" }}
                    >
                      {!BasicDetails.linkedin ? (
                        BasicDetails.linkedin
                      ) : (
                        <i className="fab fa-linkedin op5-icon"></i>
                      )}
                      &nbsp;
                      <div className="descriptionfontsize ml-2">
                        {BasicDetails.linkedin}
                      </div>
                    </div>
                  </div>

                  <div className="op5-titlespacing">
                    <p
                      className="op5-title"
                      style={{
                        fontSize: `${titlefontsize}px`,
                      }}
                    >
                      SKILLS
                    </p>

                    <div className="d-flex flex-row bd-highlight">
                      {Skills.map((item, index) => (
                        <div
                          className="bd-highlight"
                          key={index}
                          style={{
                            flex: "1",
                            display: "flex",
                            alignItems: "stretch",
                          }}
                        >
                          {item.skillname && (
                            <div
                              style={{ margin: 0 }}
                              className="labelContainer"
                            >
                              <div
                                style={{
                                  fontSize: `${descriptionfontsize}px`,
                                  flex: "1",
                                }}
                                className="op5-pill"
                              >
                                {item.skillname}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="op5-titlespacing">
                    <p
                      className="op5-title"
                      style={{
                        fontSize: `${titlefontsize}px`,
                      }}
                    >
                      WORK EXPERIENCE
                    </p>

                    {WorkExprience.map((item, index) => (
                      <div style={{ marginBottom: "10px" }} key={index}>
                        <div className="row">
                          <p
                            className="op5-sub"
                            style={{
                              fontSize: `${descriptionfontsize}px`,
                            }}
                          >
                            {item.designationName}
                          </p>
                          <p
                            className="op5-sub-sub"
                            style={{
                              fontSize: `${descriptionfontsize}px`,
                            }}
                          >
                            {item.companyName}
                          </p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div style={{ flex: "3" }}>
                            <p
                              className="op5-date"
                              style={{ fontSize: `${descriptionfontsize}px` }}
                            >
                              {item.startMonth}
                              {item.startYear && "/"}
                              {item.startYear} {item.startYear && "-"}{" "}
                              {item.endMonth}
                              {item.endYear && "/"}
                              {item.endYear}
                            </p>
                          </div>
                          <div style={{ flex: "1" }}>
                            <p
                              className="op5-date"
                              style={{
                                overflowWrap: "break-word",
                                fontSize: `${descriptionfontsize}px`,
                                textAlign: "right",
                              }}
                            >
                              {item.location}
                            </p>
                          </div>
                        </div>

                        <ul>
                          {item.description.map((subField, subIndex) => (
                            <li
                              className="circlestyle"
                              style={{
                                color: "#28b4a3",
                                fontSize: 11,
                                marginLeft: 20,
                                bottom: 0,
                              }}
                              key={subIndex}
                            >
                              <p
                                className="descriptionfontsize pcircle"
                                style={{
                                  color: "#000000",
                                  overflowWrap: "break-word",
                                  fontSize: `${descriptionfontsize}px`,
                                }}
                              >
                                <span className="op5-dash">-</span>&nbsp;
                                {subField.value}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>

                  <div className="op5-titlespacing">
                    <p
                      className="op5-title"
                      style={{
                        fontSize: `${titlefontsize}px`,
                      }}
                    >
                      EDUCATION
                    </p>

                    {Education.map((item, index) => (
                      <div
                        className="educationrow row"
                        style={{ marginBottom: 10 }}
                        key={index}
                      >
                        <p
                          className="op5-sub"
                          style={{
                            fontSize: `${descriptionfontsize}px`,
                          }}
                        >
                          {item.degreeName}
                        </p>
                        <p
                          className="op5-sub-sub"
                          style={{
                            fontSize: `${descriptionfontsize}px`,
                          }}
                        >
                          {item.collegeName}
                        </p>
                        <p
                          className="op5-date"
                          style={{
                            fontSize: `${descriptionfontsize}px`,
                          }}
                        >
                          {item.startMonth}
                          {item.startYear && "/"}
                          {item.startYear} {item.startYear && "-"}{" "}
                          {item.endMonth}
                          {item.endYear && "/"}
                          {item.endYear}
                        </p>
                        <p
                          className="descriptionfontsize"
                          style={{
                            marginTop: "0px",
                            color: "#000000",
                            fontSize: `${descriptionfontsize}px`,
                          }}
                        >
                          {item.score && `Score : ${item.score}`}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="op5-titlespacing">
                    <p
                      className="op5-title"
                      style={{
                        fontSize: `${titlefontsize}px`,
                      }}
                    >
                      CERTIFICATION
                    </p>

                    {Certification.map((item, index) => (
                      <div className="" key={index}>
                        <div style={{ marginBottom: "0px" }}>
                          <div className="row">
                            <p
                              className="op5-sub"
                              style={{
                                fontSize: `${descriptionfontsize}px`,
                              }}
                            >
                              {item.certificateName}
                            </p>
                            {/* {item.certificateName && (
                              <p
                                className="op5-date"
                                style={{
                                  fontSize: `${descriptionfontsize}px`,
                                }}
                              >
                                <a href={item.certificateLink}>
                                  View Certificate
                                </a>
                              </p>
                            )} */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {Languages[0].langugename !== "" ? (
                    <>
                      <div className="op5-titlespacing">
                        <p
                          className="op5-title"
                          style={{
                            fontSize: `${titlefontsize}px`,
                          }}
                        >
                          LANGUAGES
                        </p>

                        <div style={{ marginBottom: 10 }}>
                          {Languages.map((item, index) => (
                            <div key={index}>
                              <p
                                className="descriptionfontsize"
                                style={{
                                  color: "#000000",
                                  fontSize: `${descriptionfontsize}px`,
                                }}
                              >
                                {item.langugename}
                              </p>

                              {item.languagerating !== "" && (
                                <div>
                                  <CirlceRow value={item.languagerating} />
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : null}

                  {Awards[0].awardname !== "" ? (
                    <>
                      <div className="op5-titlespacing">
                        <p
                          className="op5-title"
                          style={{
                            fontSize: `${titlefontsize}px`,
                          }}
                        >
                          AWARDS AND ACHIVEMENTS
                        </p>

                        {Awards.map((item) => (
                          <p
                            key={item.id} // Add a unique key if possible
                            className="descriptionfontsize"
                            style={{
                              color: "#000000",
                              fontSize: `${titlefontsize}px`,
                            }}
                          >
                            {item.awardname}
                          </p>
                        ))}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default ResumeBuilderToolOptionSix;
