import Carousel from "react-multi-carousel";
import { useState, useEffect } from "react";
import { GetStudentDatafromMaster } from "../../services/jobs-service";
import {
  getDataFromLocalStorage,
  setDataInLocalStorage,
} from "../../browser-storage";
import ModelHelper from "./ModelHelper";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getDynamicTabsDatabyMentorId } from "../../services/dynamictabs-service";
import "./slidermenu.css";
//import IconContainer from "./IconContainer";
const SliderMenu = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 10,
      slidesToSlide: 1, // optional, default to 1.
    },

    mobile: {
      breakpoint: { max: 1023, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const mobresponsive = {
    mobile: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const [isYoutubeModalOpen, setIsYoutubeModalOpen] = useState(false);

  const [userMasterData, setuserMasterData] = useState({
    isReferredbyMentor: "false",
    IsUserPremium: "false",
    IsUserPremium_CB: "false",
  });

  const [getReferredMentorEmailId, setgetReferredMentorEmailId] = useState({
    ReferredMentorEmailId: "",
  });

  const [dynamicTabs, setDynamicTabs] = useState([]);
  const [dynamicTabsStudentPermissions, setdynamicTabsStudentPermissions] =
    useState([]);
  useEffect(() => {
    getStudentDataFromMasterTable();
    getDynamicTabsFunc();
  }, []);

  const getDynamicTabsFunc = async (ReferredbyMentorEmailId) => {
    const resp = await getDynamicTabsDatabyMentorId(ReferredbyMentorEmailId)
      .then((res) => {
        console.log("dynamicTabs", res);
        const dynamicTabsArray = res[0].dynamicTabsArray;
        setDynamicTabs(dynamicTabsArray);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getStudentDataFromMasterTable = async () => {
    const userDetials = getDataFromLocalStorage("user-details");
    console.log(userDetials);
    const currentrole = userDetials.role;
    const currentemail = userDetials.email;
    const resp = await GetStudentDatafromMaster(currentemail)
      .then((res) => {
        const userdata = res[0];
        const getIsReferredbyMentor = res[0].isReferredbyMentor;
        const ReferredbyMentorEmailId = res[0].ReferredbyMentorEmailId;
        const IsStudentPremium = res[0].IsUserPremium;
        const IsUserPremium_CB = res[0].IsUserPremium_CB;
        const IsUserPremium_AI = res[0].IsUserPremium_AI;
        const IsUserPremium_IP = res[0].IsUserPremium_IP;
        const IsUserPremium_ASS_SOFT = res[0].IsUserPremium_ASS_SOFT;
        const IsUserPremium_ASS_APTI = res[0].IsUserPremium_ASS_APTI;
        const IsUserPremium_RB = res[0].IsUserPremium_RB;
        const IsUserPremium_G1 = res[0].IsUserPremium_G1;
        const IsUserPremium_Branding = res[0].IsUserPremium_Branding;
        const IsUserPremium_InterviewPrep = res[0].IsUserPremium_InterviewPrep;
        const IsUserPremium_Communication = res[0].IsUserPremium_Communication;
        const IsUserPremium_MBTI = res[0].IsUserPremium_MBTI;
        const DynamicPermissions = res[0].DynamicPermissions;

        console.log("getIsReferredbyMentor", getIsReferredbyMentor);
        console.log("ReferredbyMentorEmailId", ReferredbyMentorEmailId);
        getDynamicTabsFunc(ReferredbyMentorEmailId);
        setuserMasterData({
          isReferredbyMentor: getIsReferredbyMentor,
          IsUserPremium: IsStudentPremium,
          IsUserPremium_CB: IsUserPremium_CB,
          IsUserPremium_AI: IsUserPremium_AI,
          IsUserPremium_IP: IsUserPremium_IP,
          IsUserPremium_ASS_SOFT: IsUserPremium_ASS_SOFT,
          IsUserPremium_ASS_APTI: IsUserPremium_ASS_APTI,
          IsUserPremium_RB: IsUserPremium_RB,
          IsUserPremium_G1: IsUserPremium_G1,
          IsUserPremium_Branding: IsUserPremium_Branding,
          IsUserPremium_InterviewPrep: IsUserPremium_InterviewPrep,
          IsUserPremium_Communication: IsUserPremium_Communication,
          IsUserPremium_MBTI: IsUserPremium_MBTI,
        });
        setdynamicTabsStudentPermissions(DynamicPermissions);
        console.log("DynamicPermissions", DynamicPermissions);
        setDataInLocalStorage(
          "getReferredMentorEmailId",
          ReferredbyMentorEmailId
        );
        setgetReferredMentorEmailId({
          getReferredMentorEmailId: ReferredbyMentorEmailId,
        });

        console.log(userdata);
      })
      .catch((err) => {
        console.log(err);
      });
    //console.log(userMasterData);
  };
  const ModelHandler = (item) => {
    setIsYoutubeModalOpen(true);
    setcurrentvideoCode(item);
    //console.log("currentvideoCode", currentvideoCode);
  };
  const [permissionArray, setPermissionArray] = useState([]);
  const [currentvideoCode, setcurrentvideoCode] = useState("");

  const MenuDB = [
    {
      menu_title: "Branding",
      menu_link: "/personal-branding",
      menu_icon_link: "/assets/img/ghimages/graphic-icons/gjob1.svg",
      menu_icon_alt: "Jobs Icon",
      menu_conditon: `${
        userMasterData.IsUserPremium == "true" &&
        userMasterData.IsUserPremium_Branding == "true"
      }`,
    },
    {
      menu_title: "Communication",
      menu_link: "/art-of-communication",
      menu_icon_link: "/assets/img/ghimages/graphic-icons/ggdtriangle.svg",
      menu_icon_alt: "Jobs Icon",
      menu_conditon: `${
        userMasterData.IsUserPremium == "true" &&
        userMasterData.IsUserPremium_Communication == "true"
      }`,
    },
    {
      menu_title: "Interview Prep",
      menu_link: "/interview-preparation",
      menu_icon_link: "/assets/img/ghimages/graphic-icons/interview.svg",
      menu_icon_alt: "Jobs Icon",
      menu_conditon: `${
        userMasterData.IsUserPremium == "true" &&
        userMasterData.IsUserPremium_InterviewPrep == "true"
      }`,
    },
    // {
    //   menu_title: "Mentor Course",
    //   menu_link: "/mentor-course",
    //   menu_icon_link: "/assets/img/ghimages/graphic-icons/speedmentoring.svg",
    //   menu_icon_alt: "Jobs Icon",
    //   menu_conditon: `${userMasterData.IsUserPremium == "true"}`,
    // },
    {
      menu_title: "Interpersonal",
      menu_link: "/behavioral-available-test",
      menu_icon_link: "/assets/img/ghimages/graphic-icons/thinking.svg",
      menu_icon_alt: "Jobs Icon",
      menu_conditon: `${
        userMasterData.IsUserPremium == "true" &&
        userMasterData.IsUserPremium_ASS_SOFT == "true"
      }`,
    },
    // {
    //   menu_title: "MBTI",
    //   menu_link: "/mbti-available-test",
    //   menu_icon_link: "/assets/img/ghimages/graphic-icons/brain.svg",
    //   menu_icon_alt: "Jobs Icon",
    //   menu_conditon: `${
    //     userMasterData.IsUserPremium == "true" &&
    //     userMasterData.IsUserPremium_MBTI == "true"
    //   }`,
    // },
    {
      menu_title: "Aptitude",
      menu_link: "/available-test",
      menu_icon_link:
        "/assets/img/ghimages/graphic-icons/online-examination.svg",
      menu_icon_alt: "Jobs Icon",

      menu_conditon: `${
        userMasterData.IsUserPremium == "true" &&
        userMasterData.IsUserPremium_ASS_APTI == "true"
      }`,
    },
    {
      menu_title: "Resume",
      menu_link: "/resume-builder",
      menu_icon_link: "/assets/img/ghimages/graphic-icons/resumeicon.svg",
      menu_icon_alt: "Jobs Icon",

      menu_conditon: `${
        userMasterData.IsUserPremium == "true" &&
        userMasterData.IsUserPremium_RB == "true"
      }`,
    },
    {
      menu_title: "AI Interview",
      menu_link: "/dashboard/quick-interview",
      menu_icon_link: "/assets/img/ghimages/graphic-icons/gai1.svg",
      menu_icon_alt: "Jobs Icon",

      menu_conditon: `${
        userMasterData.IsUserPremium == "true" &&
        userMasterData.IsUserPremium_AI == "true"
      }`,
    },
    // {
    //   menu_title: "Jobs",
    //   menu_link: "/jobs",
    //   menu_icon_link: "/assets/img/ghimages/graphic-icons/gjob2.svg",
    //   menu_icon_alt: "Jobs Icon",
    //   menu_conditon: `${userMasterData.IsUserPremium == "true"}`,
    // },
  ];

  return (
    <>
      {isYoutubeModalOpen && (
        <ModelHelper
          CurrentYoutubevideoCode={currentvideoCode}
          closeAction={() => setIsYoutubeModalOpen(false)}
        />
      )}
      <div className="desktop">
        <Carousel
          swipeable={false}
          draggable={false}
          showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {MenuDB.map((item) => {
            return (
              <div className="icons-container">
                {item.menu_conditon === "true" ? (
                  <>
                    <Link to={item.menu_link} state={{ data: item.menu_state }}>
                      <img
                        width="100px"
                        src={item.menu_icon_link}
                        alt={item.menu_icon_alt}
                      />
                      <p style={{ color: "#2b4eff", fontWeight: 600 }}>
                        {item.menu_title}
                      </p>
                    </Link>
                  </>
                ) : (
                  <>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => ModelHandler("Premium")}
                    >
                      <img
                        width="100px"
                        src={item.menu_icon_link}
                        alt={item.menu_icon_alt}
                      />
                      <p>{item.menu_title}</p>
                      <p style={{ color: "#2b4eff" }}>
                        <img
                          width="20px"
                          src="/assets/img/ghimages/graphic-icons/padlock2.svg"
                          alt="Lock Icon"
                        />
                        &nbsp;Premium
                      </p>
                    </div>
                  </>
                )}
              </div>
            );
          })}
          {dynamicTabs.map((item, index) => {
            console.log("item.dynamicTabCondition", item.dynamicTabCondition);
            const isUnlocked = dynamicTabsStudentPermissions.includes(
              item.dynamicTabCondition
            ); // Replace with your condition

            return (
              <div className="icons-container" key={index}>
                {isUnlocked ? (
                  <Link
                    to="/premium-mentor-course"
                    state={{ data: item.dynamicTabCondition }}
                  >
                    <img
                      width="100px"
                      src="/assets/img/ghimages/graphic-icons/dynamictab.svg"
                      alt="mentor course"
                    />
                    <p style={{ color: "#2b4eff", fontWeight: 600 }}>
                      {item.dynamicTabTitle}
                    </p>
                  </Link>
                ) : (
                  // You can customize the locked state as needed

                  <>
                    <div
                      style={{ cursor: "pointer" }}
                      //onClick={() => ModelHandler("Premium")}
                    >
                      <img
                        width="100px"
                        src="/assets/img/ghimages/graphic-icons/dynamictab.svg"
                        alt="Locked"
                      />
                      <p>{item.dynamicTabTitle}</p>
                      <p style={{ color: "#2b4eff" }}>
                        <img
                          width="20px"
                          src="/assets/img/ghimages/graphic-icons/padlock2.svg"
                          alt="Lock Icon"
                        />
                        &nbsp;Premium
                      </p>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </Carousel>
      </div>
      <div className="mobile">
        {/* <Carousel
          centerMode={true}
          swipeable={true}
          draggable={false}
          showDots={true}
          responsive={mobresponsive}
          keyBoardControl={true}
          containerClass="carousel-container"
        >
          {MenuDB.map((item) => {
            return (
              <div className="icons-container">
                {item.menu_conditon === "true" ? (
                  <>
                    <Link to={item.menu_link} state={{ data: item.menu_state }}>
                      <img
                        width="100px"
                        src={item.menu_icon_link}
                        alt={item.menu_icon_alt}
                      />
                      <p style={{ color: "#2b4eff", fontWeight: 600 }}>
                        {item.menu_title}
                      </p>
                    </Link>
                  </>
                ) : (
                  <>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => ModelHandler("Premium")}
                    >
                      <img
                        width="100px"
                        src={item.menu_icon_link}
                        alt={item.menu_icon_alt}
                      />
                      <p>{item.menu_title}</p>
                      <p style={{ color: "#2b4eff" }}>
                        <img
                          width="20px"
                          src="/assets/img/ghimages/graphic-icons/padlock2.svg"
                          alt="Lock Icon"
                        />
                        &nbsp;Premium
                      </p>
                    </div>
                  </>
                )}
              </div>
            );
          })}
          {dynamicTabs.map((item, index) => {
            console.log("item.dynamicTabCondition", item.dynamicTabCondition);
            const isUnlocked = dynamicTabsStudentPermissions.includes(
              item.dynamicTabCondition
            ); // Replace with your condition

            return (
              <div className="icons-container" key={index}>
                {isUnlocked ? (
                  <Link
                    to="/premium-mentor-course"
                    state={{ data: item.dynamicTabCondition }}
                  >
                    <img
                      width="100px"
                      src="/assets/img/ghimages/graphic-icons/dynamictab.svg"
                      alt="mentor course"
                    />
                    <p style={{ color: "#2b4eff", fontWeight: 600 }}>
                      {item.dynamicTabTitle}
                    </p>
                  </Link>
                ) : (
                  // You can customize the locked state as needed

                  <>
                    <div
                      style={{ cursor: "pointer" }}
                      //onClick={() => ModelHandler("Premium")}
                    >
                      <img
                        width="100px"
                        src="/assets/img/ghimages/graphic-icons/dynamictab.svg"
                        alt="Locked"
                      />
                      <p>{item.dynamicTabTitle}</p>
                      <p style={{ color: "#2b4eff" }}>
                        <img
                          width="20px"
                          src="/assets/img/ghimages/graphic-icons/padlock2.svg"
                          alt="Lock Icon"
                        />
                        &nbsp;Premium
                      </p>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </Carousel> */}
        <div className="mt-20 mb-30">
          <Slider {...settings}>
            {MenuDB.map((item, index) => {
              return (
                <div className="text-center">
                  {item.menu_conditon === "true" ? (
                    <>
                      <Link
                        to={item.menu_link}
                        state={{ data: item.menu_state }}
                      >
                        <img
                          width="100px"
                          style={{ display: "block", margin: "0 auto" }}
                          src={item.menu_icon_link}
                          alt={item.menu_icon_alt}
                        />
                        <p style={{ color: "#2b4eff", fontWeight: 600 }}>
                          {item.menu_title}
                        </p>
                      </Link>
                    </>
                  ) : (
                    <>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => ModelHandler("Premium")}
                      >
                        <img
                          width="100px"
                          style={{ display: "block", margin: "0 auto" }}
                          src={item.menu_icon_link}
                          alt={item.menu_icon_alt}
                        />
                        <p>{item.menu_title}</p>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            width="20px"
                            style={{ display: "block", margin: "0 auto" }}
                            src="/assets/img/ghimages/graphic-icons/padlock2.svg"
                            alt="Lock Icon"
                          />
                          <p style={{ color: "#2b4eff", marginLeft: "5px" }}>
                            Premium
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
            {dynamicTabs.map((card, index) => (
              <div key={index} className="text-center">
                <img
                  style={{ display: "block", margin: "0 auto" }}
                  width="100px"
                  src="/assets/img/ghimages/graphic-icons/dynamictab.svg"
                  alt={card.menu_icon_alt}
                />

                <p style={{ color: "#2b4eff", fontWeight: 600 }}>
                  {card.dynamicTabTitle}
                </p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default SliderMenu;
