import { useCalendlyEventListener, InlineWidget } from "react-calendly";

import React, { forwardRef, useState, useEffect } from "react";
import JobsPopupLoginForm from "../../pages/jobs/jobs-popup-login-form";
import { getDataFromLocalStorage } from "../../browser-storage";
import Modal from "react-modal";
import Wrapper from "../../layout/wrapper";
import { getWhiteLabelConfig } from "../../services/whitelabel-config";
import {
  AddSimpleStudentCouponRecordService,
  AddStudentCouponRecordService,
  checkIsCouponValid,
} from "../../services/coupon-service";
import { razorpayCreateOrderWithSplitPayment } from "../../services/payment-service";
import {
  addserviceScheduler,
  addSimpleStudentScheduler,
} from "../../services/scheduler-service";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import StudentEnquiryForm from "../../pages/enquiry-forms/student-enquiry-form";
import WhiteEnquiryForm from "./white-enquiry-form";
import { AddStudentEnquiryForm } from "../../services/user-service";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
let fullHostname = window.location.hostname;
const WhiteOneOnOneBooking = () => {
  const navigate = useNavigate();
  const [isDoneScheduling, setDoneScheduling] = useState(false);
  const [isscheduledone, setscheduledone] = useState(false);
  const [isTimeSelected, setTimeSelected] = useState(false);
  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => {
      if (e) {
        setscheduledone(true);
        setscheduledone(true);
      }
    },
  });

  const pageSettings = {
    primaryColor: "417658",
    textColor: "251e35",
    backgroundColor: "f7f5f4",
    hideLandingPageDetails: true,
    hideEventTypeDetails: true,
  };

  function getCalendlyHeightToEnsureNoCrop() {
    if (!isTimeSelected) return { height: 600 };
    if (isDoneScheduling) return { height: 450 };
    return { height: 660 };
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "600px",
    },
  };
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  let applyJobHandler = () => {
    openModal();
  };

  const [Hostname, setHostname] = useState();

  const [isRazorpayPaymentDone, setisRazorpayPaymentDone] = useState("false");
  const [payments, setPayments] = useState({
    IsBookingPaid: "",
    SessionBookingPrice: "",
    MentorCouponKey: "",
    MentorCalendlyId: "",
  });
  const _user = localStorage.getItem("user");

  useEffect(() => {
    setCurrentSource(fullHostname);
    const fetchWhiteLabelConfig = async () => {
      try {
        const Hostname = window.location.hostname;
        setHostname(Hostname);
        const data = await getWhiteLabelConfig(Hostname);
        console.log("Header data", data);

        const IsBookingPaid = data[0].IsBookingPaid;

        const { SessionBookingPrice, MentorCouponKey, MentorCalendlyId } =
          data[0];

        setPayments((prevState) => ({
          ...prevState,
          SessionBookingPrice,
          MentorCouponKey,
          MentorCalendlyId,
          IsBookingPaid,
        }));
      } catch (err) {
        console.log("White Error", err);
        // Handle error if necessary
      }
    };

    fetchWhiteLabelConfig();
  }, [Hostname]);
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const handlerpayment = () => {};
  const [currentStudentDetails, setCurrentStudentDetails] = useState();
  const [currentSource, setCurrentSource] = useState();
  const [selectedpackageprice, setselectedpackageprice] = useState("");
  const [coupondetails, setCouponDetails] = useState({
    coupon_code_submitted: "",
  });

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    console.log("selectedpackageprice", selectedpackageprice);

    console.log("inside loop");
    //Step 5 Mentor Share
    //dont add razorpay value in this

    const submittedNumber = parseFloat(payments.SessionBookingPrice);

    const customMentorShare = submittedNumber * 0.882;
    console.log("customMentorShare", customMentorShare);
    const calculatedResult = submittedNumber + submittedNumber * 0.027; // 2.7%
    console.log("calculatedResult", calculatedResult);
    const payload = {
      amount: Number(calculatedResult) * 100,
      discount_code_used: payments.MentorCouponKey,
      mygreenhorn_amount: "",
      mentor_amount: Number(customMentorShare) * 100,
      //amount: 100,
    };
    console.log("payload", payload);
    const result = await razorpayCreateOrderWithSplitPayment(payload)
      .then((res) => {
        console.log(res);
        let response = res;
        const id = response.id;
        const options = {
          currency: "INR",
          name: "GreenHorn",
          order_id: id,
          description: "GreenHorn Transaction",
          handler: async function (response) {
            const data = {
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };
            console.log(response);

            const payload = {
              servicebooked: "1 on 1 Booking",
              bookingDate: new Date(),
              amount: payments.SessionBookingPrice,
              source: currentSource,
              discount_code_used: payments.MentorCouponKey,
              mentor_amount: customMentorShare,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };
            const schedulerResult = await addSimpleStudentScheduler(payload)
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });
            const coupon_payload = {
              mentor_coupon_code: payments.MentorCouponKey,
              mentor_name: "",
              mentorEmailId: "",
              source: currentSource,
              generation_date: new Date(),
              //StudentEmailId: currentStudentDetails.userName,
              StudentName: details.fullname,
              PackageSelected: "Booked 1 on 1",
            };
            const Couponrecordresult =
              await AddSimpleStudentCouponRecordService(coupon_payload)
                .then((res) => {
                  console.log(res);
                })
                .catch((err) => {
                  console.log(err);
                });

            console.log(schedulerResult);
            console.log("Couponrecordresult", Couponrecordresult);
            if (response) {
              toast.success(
                `Payment Successfully Completed, Please check your mail for more detials `,
                {
                  position: "top-left",
                }
              );
              //navigate("/paid-one-on-one-booking");
              setisRazorpayPaymentDone("true");
            }
          },
          theme: {
            color: "#61dafb",
          },
        };

        const paymentObject = new window.Razorpay(options);

        paymentObject.open();
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(result);
  }
  const [error, setError] = useState("");
  const CurrentDate = new Date()
    .toJSON()
    .slice(0, 10)
    .split("-")
    .reverse()
    .join("/");
  const [details, setDetails] = useState({
    fullname: "",
    mobile: "",
    preferreddomain: "",
    submitteddate: CurrentDate,
  });

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!details.fullname || !details.mobile) {
      return;
    }

    const SubmitResponse = await AddStudentEnquiryForm(details)
      .then((resp) => {
        setEnquiryDone("true");
      })
      .catch((err) =>
        toast.error(`Error ${err}`, {
          position: "top-left",
        })
      );
  };
  const [EnquiryDone, setEnquiryDone] = useState("false");
  const [validated, setValidated] = useState(false);
  const handleValidation = (event) => {
    const input = event.target.value.trim();
    const controlId = event.target.id;

    if (controlId === "email") {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
      if (!isValidEmail) {
        event.target.setCustomValidity("Please enter a valid email address.");
      } else {
        event.target.setCustomValidity("");
      }
    } else if (controlId === "mobileNumber") {
      const isValidMobileNumber = /^\d{10}$/.test(input);
      if (!isValidMobileNumber) {
        event.target.setCustomValidity("Mobile number must be 10 digits.");
      } else {
        event.target.setCustomValidity("");
      }
    }

    setValidated(true);
  };
  const handlepaymentcomplete = () => {
    setisRazorpayPaymentDone("true");
  };

  const calculateResult = (number) => {
    if (number !== "") {
      const submittedNumber = parseFloat(number);
      const calculatedResult = submittedNumber + submittedNumber * 0.027; // 2.7%
      return calculatedResult.toFixed(2);
    }
    return "";
  };

  return (
    <>
      <Wrapper>
        <div className="container">
          <div className="row mt-10">
            <div className="col-md-9">
              {/* <button onClick={handlepaymentcomplete}>test complete</button> */}
            </div>
            <div className="col-md-3">
              <Link className="e-btn" to={"/"}>
                Back to Home
              </Link>
            </div>
          </div>
        </div>
        {EnquiryDone == "false" ? (
          <Card style={{ margin: 30, background: "white" }}>
            <div style={{ padding: 25 }}>
              <Form noValidate validated={validated}>
                <Form.Group as={Col} md="12" controlId="firstName">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    value={details.fullname}
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        fullname: e.target.value,
                      })
                    }
                    type="text"
                    placeholder="Full Name"
                    name="fullname"
                    autocapitalize="none"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide your full name.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="12" controlId="mobileNumber">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    type="tel"
                    pattern="[0-9]{10}"
                    placeholder="Enter your mobile number"
                    onBlur={handleValidation}
                    value={details.mobile}
                    onChange={(e) =>
                      setDetails({ ...details, mobile: e.target.value })
                    }
                    name="mobile"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Mobile number must be 10 digits.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="12" controlId="email">
                  <Form.Label>Email address</Form.Label>
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      aria-describedby="inputGroupPrepend"
                      name="email"
                      onChange={(e) =>
                        setDetails({
                          ...details,
                          email: e.target.value.toLowerCase(),
                        })
                      }
                      value={details.email}
                      required
                      autocapitalize="none"
                      onBlur={handleValidation}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid email address.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Button className="mt-5" type="button" onClick={submitHandler}>
                  Submit
                </Button>
              </Form>
            </div>
          </Card>
        ) : null}

        {EnquiryDone == "true" &&
        payments.IsBookingPaid == "true" &&
        isRazorpayPaymentDone == "false" ? (
          <>
            <div className="container mt-10">
              <Alert variant="success">
                <Alert.Heading>
                  You have successfully submitted your form. Now proceed to make
                  payment to book your slot
                  {/* const calculatedResult = submittedNumber +
                  submittedNumber * 0.027; // 2.7%  */}
                  <br />
                  Final Price: {payments.SessionBookingPrice} + 2.7% transcation
                  fee = {calculateResult(payments.SessionBookingPrice)}
                </Alert.Heading>

                <hr />
                <button
                  className="e-btn btn-success"
                  style={{ marginTop: "5px", background: "green" }}
                  onClick={displayRazorpay}
                >
                  Book Now
                </button>
              </Alert>

              <br />
              <div className="row text-center">
                <div>
                  <img
                    width="450px"
                    style={{ width: 450 }}
                    src="https://mygreenhorn.in/Videos/calenderlock.jpg"
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}

        {EnquiryDone == "true" && payments.IsBookingPaid == "false" ? (
          <>
            <div className="container">
              <Alert variant="success">
                <Alert.Heading>
                  You have successfully submitted your form. Now proceed to book
                  your slot
                </Alert.Heading>
                <hr />
              </Alert>
            </div>
            {payments.MentorCalendlyId ? (
              <>
                <InlineWidget
                  url={payments.MentorCalendlyId}
                  pageSettings={pageSettings}
                  styles={getCalendlyHeightToEnsureNoCrop()}
                />
              </>
            ) : null}
          </>
        ) : null}
        {isRazorpayPaymentDone == "true" ? (
          <>
            <div className="container mt-10">
              <Alert variant="success">
                <Alert.Heading>
                  You have successfully made your payment. Now choose your
                  convenient slot to speak with your mentor
                </Alert.Heading>
                <hr />
              </Alert>
            </div>
            {payments.MentorCalendlyId ? (
              <>
                <InlineWidget
                  url={payments.MentorCalendlyId}
                  pageSettings={pageSettings}
                  styles={getCalendlyHeightToEnsureNoCrop()}
                />
              </>
            ) : null}
          </>
        ) : null}
      </Wrapper>
    </>
  );
};

export default WhiteOneOnOneBooking;
