import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    document.body.scrollTop = document.body.scrollHeight;
  }, []);
  return (
    <Wrapper>
      <SEO pageTitle={"Privacy Policy"} />
      <div className="page__title-shape">
        <img
          className="page-title-shape-5 d-none d-sm-block"
          src="/assets/img/page-title/page-title-shape-1.png"
          alt=""
        />
        <img
          className="page-title-shape-6"
          src="/assets/img/page-title/page-title-shape-6.png"
          alt=""
        />
        <img
          className="page-title-shape-7"
          src="/assets/img/page-title/page-title-shape-4.png"
          alt=""
        />
      </div>

      <div style={{ marginTop: 20 }} className="container">
        <h2>Privacy Policy for this website</h2>
        <p>
          Our Privacy Policy covers how we collect, use, disclose, transfer, and
          store your information. Your privacy is important to us and to ensure
          that your personal information is secure, we communicate its privacy
          and security guidelines to our employees and strictly enforce privacy
          safeguards within the company. Please familiarize yourself with our
          privacy practices. 1. Collection &amp; Usage of Personal Information
          Personal information is data that can be used to uniquely identify or
          contact a single person. You may be asked to provide your personal
          information anytime you are in contact with us or our affiliates or
          our authorized business partners (which includes agents, contractors,
          vendors, licensors, resellers, system integrators, etc.). When you
          purchase our services, register for utilizing our services, contact us
          to report service issues, apply for credit, or participate in an
          online survey, we, our affiliates or authorized business partners may
          collect various information, including your name, mailing address,
          phone number, email address, contact preferences, unique device
          identifier and credit card information. We, our affiliates and our
          business partners may share this personal information with each other
          and use it consistent with this Privacy Policy. They may also combine
          it with other information to provide and improve our services,
          content, and advertising. The personal information we collect allows
          us to keep you posted on the latest services announcements, services
          updates and upcoming events. It also helps us to improve its content,
          and advertising. You can opt out by sending a notice to our return
          email address at any time. Please note that opting out may impact your
          ability to use some of our services. We may use your personal
          information to send important notices, such as communications about
          purchases, changes to the terms, conditions, and policies, your
          compliance/non-compliance with the terms and conditions of usage of
          our services, etc. Because this information is important to your
          interaction with us, you may not opt out of receiving these
          communications.We may also use personal information for internal
          purposes such as auditing, data analysis, and research to improve our
          services, and customer communications. If you enter into a sweepstake,
          contest, or similar promotion we may use the information you provide
          to administer those programs. 2. Collection &amp; Usage of
          Non-Personal Information Non-personal information is data in a form
          that does not permit direct association with any specific individual.
          We may collect, use, transfer, and disclose non-personal information
          for any purpose. We may collect information such as unique device
          identifier, location, details on services usage and the time zone
          where a service is used so that we can better understand customer
          behaviour and improve its services, and advertising. Where applicable,
          we may collect and review your content to ensure your compliance with
          the terms and conditions of usage of its services, etc. We also may
          collect information regarding customer activities on our website. This
          information is aggregated and used to provide more useful information
          to its customers and to understand which parts of our website,
          products, and services are of most interest. Aggregated data is
          considered non-personal information for the purposes of this Privacy
          Policy. If we combines non-personal information with personal
          information the combined information will be treated as personal
          information, as long as it remains combined and will be subject to the
          policy mentioned in section 1 above. 3. Cookies &amp; Other
          Technologies: Our website, online services, interactive applications,
          email messages, and advertisements may use “cookies” and other
          technologies such as pixel tags and web beacons. These technologies
          enable us to understand user behaviour, identify which parts of the
          website the users have visited, and facilitate and measure the
          effectiveness of advertisements and web searches. We treat information
          collected by cookies and other technologies as non-personal
          information. However, to the extent that Internet Protocol (IP)
          addresses or similar identifiers are considered personal information
          by local law, We also treat these identifiers as personal information.
          Similarly, to the extent that non-personal information is combined
          with personal information, we treat the combined information as
          personal information for the purposes of this Privacy Policy. We and
          our partners may also use cookies and other technologies to remember
          personal information when you use the website, online services, and
          products/services. The objective in such instances is to make your
          experience with us more convenient and personal. When you visit the
          website, we may gather some information automatically and store it in
          log files. This information includes Internet Protocol (IP) addresses,
          browser type and language, Internet service provider (ISP), referring
          and exit pages, operating system, date/time stamp, and clickstream
          data. We use this information to understand and analyse trends, to
          administer the site, to learn about user behaviour on the site, and to
          gather demographic information about its user base as a whole. We may
          use this information in our marketing and advertising. In some of its
          email messages, we may use a “click- through URL” linked to content on
          our website. When customers click one of these URLs, they pass through
          a separate web server before arriving at the destination page on the
          website. This click-through data is tracked to enable the
          determination of interest in particular topics and measure the
          effectiveness of the customer communications. If you prefer not to be
          tracked in this way, you should not click text or graphic links in the
          email messages. Pixel tags enable us to send email messages in a
          format customers can read, and they indicate whether mail has been
          opened. We may use this information to reduce or eliminate messages
          sent to customers. 4. Disclosure to Third Parties At times we may make
          certain personal information available to partners that work with us
          to provide services, or that help us market to customers. For example,
          when you purchase our services, you authorize us and our service
          providers to exchange the information you provide during the
          activation process to carry out services. If you are approved for
          services, your account will be governed by us and our service
          providers’ privacy policies. Personal information will only be used by
          us to provide or improve its services and advertising; it will not be
          shared with third parties for their marketing purposes. We share
          personal information with partners who provide services such as
          information processing, extending credit, fulfilling customer orders,
          managing and enhancing customer data, providing customer service,
          assessing your interest in our services, and conducting customer
          research or satisfaction surveys. These companies are obligated to
          protect your information and may be located wherever we operate. It
          may be necessary − by law, legal process, litigation, and/or requests
          from public and governmental authorities within or outside your
          country of residence − for us to disclose your personal information.
          We may also disclose information about you if we determine that for
          purposes of national security, law enforcement, or other issues of
          public importance, disclosure is necessary or appropriate. We may also
          disclose relevant information if we determine that disclosure is
          reasonably necessary to enforce its terms and conditions or protect
          its operations or users. Additionally, in the event of a
          reorganization, merger, or sale, we may transfer any and all personal
          information we collect to the relevant third-party. 5. Protection of
          Personal Information: We take precautions including administrative,
          technical, and physical measures to safeguard your personal
          information against loss, theft, and misuse, as well as against
          unauthorized access, disclosure, alteration, and destruction. When you
          use some of our services or post on our forum, chat room, or social
          networking service, the personal information you share is visible to
          other users and can be read, collected, or used by them. You are
          responsible for the personal information you choose to submit in these
          instances. 6. Integrity and Retention of Personal Information: we will
          continue to expand your ability and make it easy for you to keep your
          personal information accurate, complete, and up to date. We will
          retain your personal information for the period necessary to fulfil
          the purposes outlined in this Privacy Policy unless a longer retention
          period is required or prescribed by law. 7. Access to Personal
          Information: For certain services, we request user personal data such
          as email address information to complete registration and authenticate
          you as a valid user . In the future, as additional information is
          required for renewals or future registrations, we will make good faith
          efforts to provide you with access so that you can request for us to
          correct the data if it is inaccurate or delete the data if we are not
          required to retain it by law or for legitimate business purposes. We
          may decline to process requests that are unreasonably repetitive,
          require disproportionate technical effort, jeopardize the privacy of
          others, are extremely impractical, or for which access is not
          otherwise required by local law. 8. Third-Party Sites and Services:
          Our websites and services may contain links to third-party websites,
          products, and services. Our services may reference other sites offered
          by third parties. Information collected by third parties, which may
          include additional information or requirements, is governed by their
          respective privacy practices. We encourage you to learn about the
          privacy practices of those third parties. 9. Users who fill enrolment
          form or any other form capturing user data on forms generated on
          social media channels or on our website agrees to expressly authorize
          us to send service related transactional SMS messages and gives them
          permission to call them, even in the case that Member&#39;s contact
          number is listed as DND with the concerned telecom regulatory
          authority. In a few instances we have used content created by other
          creators that are available on public domains such as YouTube. We
          would like to confirm that these are used only for the benefit of the
          society to meet the ends of educational purposes as per the Doctrine
          of Fair Use Policy. We would like to thank the creators and also
          provide them credits for their content. In case the creators feel that
          we should not be using their content, they can contact us and we will
          take necessary action to take the content off our platform at the
          earliest.
        </p>
      </div>
    </Wrapper>
  );
};

export default PrivacyPolicy;
