import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LOGO_URL } from "../../layout/headers/header";
import { menu_bar, selectMenuBar } from "../../redux/features/header-slice";
import { getWhiteLabelConfig } from "../../services/whitelabel-config";
import student_menu_data from "../../data/student-menu-data";
import { Link, useNavigate } from "react-router-dom";
import {
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  REMOVE_STUDENT_ROLE,
  SET_ROLE_AS_STUDENT,
} from "../../redux/features/profile-slice";
let fullHostname = window.location.hostname;
const _user = localStorage.getItem("user");
const Sidebar = ({ currentRoleHeader }) => {
  const navigate = useNavigate();
  const menuOpen = useSelector(selectMenuBar);
  const dispatch = useDispatch();
  const [currentMenu, setcurrentMenu] = useState("menu_data");
  const [currentRole, setcurrentRole] = useState("GUEST");
  const [GuestMenuData, setGuestMenuData] = useState([]);
  const [StudentMenuData, setStudentMenuData] = useState([]);
  const [MainWebsiteURL, setMainWebsiteURL] = useState(null);
  const [socialnpayments, setSocialpayments] = useState({
    InstagramUrl: "",
    FacebookURL: "",
    YoutubeURL: "",
    LinkedInURL: "",
    IsBookingPaid: "",
    SessionBookingPrice: "",
    MentorCouponKey: "",
  });

  console.log("currentRoleHeader", currentRoleHeader);

  useEffect(() => {
    const Hostname = window.location.hostname;
    //setHostname(Hostname);
    const res = getWhiteLabelConfig(Hostname)
      .then((data) => {
        console.log("Header data", data);
        const current_logo_url = data[0].logoURL;
        const LogoWidth = data[0].DynamicWH3;
        const MainWebsiteURL = data[0].DynamicWH4;
        const GuestMenuData = data[0].GuestMenuData;
        const StudentMenuData = data[0].StudentMenuData;
        const InstagramUrl = data[0].InstagramUrl;
        const FacebookURL = data[0].FacebookURL;
        const YoutubeURL = data[0].YoutubeURL;
        const LinkedInURL = data[0].LinkedInURL;
        const IsBookingPaid = data[0].IsBookingPaid;
        const SessionBookingPrice = data[0].SessionBookingPrice;
        const MentorCouponKey = data[0].MentorCouponKey;

        setMainWebsiteURL(MainWebsiteURL);
        setSocialpayments({
          InstagramUrl: InstagramUrl,
          FacebookURL: FacebookURL,
          YoutubeURL: YoutubeURL,
          LinkedInURL: LinkedInURL,
          IsBookingPaid: IsBookingPaid,
          SessionBookingPrice: SessionBookingPrice,
          MentorCouponKey: MentorCouponKey,
        });
        setGuestMenuData(GuestMenuData, () => {
          console.log("GuestMenuData success");
        });
        setStudentMenuData(StudentMenuData, () => {
          console.log("StudentMenuData success");
        });
      })
      .catch((err) => console.log("White Error", err));
    //setHostname(fullHostname);
    if (_user) {
      const user = JSON.parse(_user);
      const currentRoletemp = JSON.stringify(user.role);
      setcurrentRole(currentRoletemp);
    }
    console.log();
  }, [fullHostname]);

  // useEffect(() => {
  //   const user = JSON.parse(_user);
  //   if (user) {
  //     const currentRoletemp = user.role;
  //     setcurrentRole(role);
  //     if (role === "ADMIN") {
  //     } else if (role === "STUDENT") {
  //       setcurrentMenu(student_menu_data);
  //     }
  //   } else {
  //     setcurrentRole("GUEST");
  //   }
  // }, [_user, currentRole]);

  const handleLogout = () => {
    dispatch(menu_bar(false));
    dispatch(REMOVE_STUDENT_ROLE());
    localStorage.removeItem("user");
    localStorage.removeItem("_user");
    localStorage.removeItem("user-details");
    setcurrentRole("GUEST");
    navigate("/student-login", { replace: true });
  };
  return (
    <>
      <div className={menuOpen ? "sidebar__area open" : "sidebar__area"}>
        <div className="sidebar__wrapper">
          <div className="sidebar__close">
            <button
              className="sidebar__close-btn"
              id="sidebar__close-btn"
              onClick={() => dispatch(menu_bar(false))}
            >
              <span>
                <i className="far fa-times"></i>
              </span>
              <span>close</span>
            </button>
          </div>
          <div className="sidebar__content">
            <div className="logo mb-40">
              <Link to="/">
                <img width={100} src={LOGO_URL} alt="logo" />
              </Link>
            </div>
            {/* <div className="mm-menu">
              <ul>
                <li onClick={() => dispatch(menu_bar(false))}>
                  <Link to="/">Home </Link>
                </li>

                <li onClick={() => dispatch(menu_bar(false))}>
                  <Link to="/student-login">Sign in</Link>
                </li>
              </ul>
            </div> */}
            {/* New Code */}
            {currentRoleHeader === "STUDENT" ? (
              <ul>
                {/* Social media icons */}
                {socialnpayments.InstagramUrl ? (
                  <>
                    <li onClick={() => dispatch(menu_bar(false))}>
                      <a target="_blank" href={socialnpayments.InstagramUrl}>
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </>
                ) : null}

                {socialnpayments.FacebookURL ? (
                  <li onClick={() => dispatch(menu_bar(false))}>
                    <a target="_blank" href={socialnpayments.FacebookURL}>
                      <i className="fab fa-facebook"></i>
                    </a>
                  </li>
                ) : null}
                {socialnpayments.LinkedInURL ? (
                  <li onClick={() => dispatch(menu_bar(false))}>
                    <a target="_blank" href={socialnpayments.LinkedInURL}>
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                ) : null}
                {socialnpayments.YoutubeURL ? (
                  <li>
                    <a target="_blank" href={socialnpayments.YoutubeURL}>
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                ) : null}

                {StudentMenuData.map((link, index) => (
                  <li
                    onClick={() => dispatch(menu_bar(false))}
                    key={index}
                    className=""
                  >
                    <Link to={`${link.MenuLink}`}>{link.MenuTitle}</Link>
                  </li>
                ))}
              </ul>
            ) : (
              <>
                {" "}
                <ul>
                  {/* Social media icons */}
                  {socialnpayments.InstagramUrl ? (
                    <>
                      <li>
                        <a target="_blank" href={socialnpayments.InstagramUrl}>
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </>
                  ) : null}

                  {socialnpayments.FacebookURL ? (
                    <li>
                      <a target="_blank" href={socialnpayments.FacebookURL}>
                        <i className="fab fa-facebook"></i>
                      </a>
                    </li>
                  ) : null}
                  {socialnpayments.LinkedInURL ? (
                    <li>
                      <a target="_blank" href={socialnpayments.LinkedInURL}>
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                  ) : null}
                  {socialnpayments.YoutubeURL ? (
                    <li>
                      <a target="_blank" href={socialnpayments.YoutubeURL}>
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  ) : null}
                  {/* {socialnpayments.FacebookURL ? :null}
                   */}

                  {MainWebsiteURL ? (
                    <li>
                      <a href={MainWebsiteURL}>Home</a>
                    </li>
                  ) : null}

                  {GuestMenuData.map((link, index) => (
                    <li key={index} className="">
                      <Link to={`${link.MenuLink}`}>{link.MenuTitle}</Link>
                    </li>
                  ))}
                </ul>
                <Link
                  onClick={() => dispatch(menu_bar(false))}
                  className="gradient-button"
                  to="/one-on-one-booking"
                >
                  1:1 Call
                </Link>
                <Link
                  onClick={() => dispatch(menu_bar(false))}
                  to="/student-login"
                  className="e-btn"
                >
                  Sign in
                </Link>
              </>
            )}

            {currentRoleHeader == "STUDENT" ? (
              <div onClick={() => handleLogout()} className="e-btn">
                Logout
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {/* <!-- sidebar overlay end --> */}
      <div
        onClick={() => dispatch(menu_bar(false))}
        className={`body-overlay ${menuOpen ? "opened" : ""}`}
      ></div>
      {/* <!-- sidebar overlay end --> */}
    </>
  );
};

export default memo(Sidebar);
